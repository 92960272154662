import {
  Dialog,
  dialogClasses,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Autocomplete,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { IconButtonOutlined } from "../components/IconButtonOutlined";
import IcClose from "../assets/icons/IcClose";
import { useEffect, useState } from "react";
import { ZSwitch } from "../components/ZSwitch";
import ZCheckbox from "../components/ZCheckbox";
import ZRangeSlider from "../components/ZRangeSlider";
import ZInput from "../components/ZInput";
import { enqueueSnackbar } from "notistack";
import { RtmGetProfile, RtmUpdatePreferences } from "../core/rtm";

export interface TradingJournalFilter {
  tradeSide: "any" | "short" | "long";
  instruments: string[];
  news: {
    show: boolean;
    mid: boolean;
    low: boolean;
    high: boolean;
  };
  profit: {
    enabled: boolean;
    min: number;
    max: number;
  };
}

const TRADE_SIDES = [
  { label: "Any", value: "all" },
  { label: "Long", value: "long" },
  { label: "Short", value: "short" },
];

export default function TradingJournalFilterDialog(props: {
  onClose?: (filter?: TradingJournalFilter) => void;
}) {
  const [tradeSide, setTradeSide] = useState("all");
  const [instruments, setInstruments] = useState<string[]>([]);
  const [showNews, setShowNews] = useState(false);
  const [showProfitRange, setShowProfitRange] = useState(false);
  const [news, setNews] = useState({ mid: false, low: false, high: false });
  const [profitRange, setProfitRange] = useState([0, 100]);
  const [busy, setBusy] = useState(false);

  async function load() {
    try {
      setBusy(true);
      const _profile = await RtmGetProfile();
      const _filter = _profile.preferences?.tradingJournalFilter || {
        instruments: [],
        news: { high: true, low: false, mid: false, show: true },
        profit: { enabled: false, max: 100, min: 0 },
        tradeSide: "any",
      };
      if (_filter) {
        setTradeSide(_filter.tradeSide);
        setInstruments([..._filter.instruments]);
        setShowNews(_filter.news.show);
        setShowProfitRange(_filter.profit.enabled);
        setProfitRange([_filter.profit.min, _filter.profit.max]);
        setNews({
          high: _filter.news.high,
          low: _filter.news.low,
          mid: _filter.news.mid,
        });
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again.", {
        variant: "error",
      });
      console.error("Error loading filter. ", err);
    }
    setBusy(false);
  }

  async function save() {
    try {
      setBusy(true);
      const _filter = {
        instruments: instruments,
        news: {
          high: news.high,
          low: news.low,
          mid: news.mid,
          show: showNews,
        },
        profit: {
          enabled: showProfitRange,
          max: profitRange[1],
          min: profitRange[0],
        },
        tradeSide: tradeSide as any,
      };
      await RtmUpdatePreferences({
        tradingJournalFilter: _filter,
      });
      props.onClose?.(_filter);
    } catch (err: any) {
      enqueueSnackbar("Error saving data. Please try again.", {
        variant: "error",
      });
      console.error("Error updating filter. ", err);
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Dialog
      open
      onClose={() => props.onClose?.()}
      sx={{
        padding: "8px",
        [`& .${dialogClasses.paper}`]: {
          borderRadius: "24px",
          background: "#FFF",
          minWidth: "522px",
        },
      }}
    >
      <DialogTitle>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              color: "#1F2937",
              fontSize: 24,
              fontWeight: 600,
            }}
          >
            Filters
          </Typography>

          <IconButtonOutlined onClick={() => props.onClose?.()}>
            <IcClose />
          </IconButtonOutlined>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={"12px"}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                color: "#1F2937",
              }}
            >
              Trade side
            </Typography>
            <ToggleButtonGroup
              exclusive
              disabled={busy}
              value={tradeSide}
              onChange={(e, v) => setTradeSide(v || tradeSide)}
            >
              {TRADE_SIDES.map((vf) => (
                <ToggleButton value={vf.value}>{vf.label}</ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Stack>
          <Divider />
          <Stack direction={"row"} alignItems={"center"}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                color: "#1F2937",
              }}
            >
              Instruments
            </Typography>
          </Stack>
          <Autocomplete
            disabled={busy}
            options={[]}
            freeSolo
            value={instruments}
            multiple
            onChange={(e, v) => {
              setInstruments([...v]);
            }}
            renderInput={(props) => (
              <TextField
                variant="standard"
                {...props}
                placeholder="Type or select..."
              />
            )}
          />
          <Divider />
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#1F2937",
                }}
              >
                News
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: "#5F6B83",
                }}
              >
                Select what news you want to see.
              </Typography>
            </Stack>
            <ZSwitch
              disabled={busy}
              checked={showNews}
              onChange={(e, c) => setShowNews(c)}
            />
          </Stack>
          {showNews && (
            <Stack>
              <ZCheckbox
                label="Low Impact"
                disabled={busy}
                value={news.low}
                onChange={(c) => setNews({ ...news, low: c })}
              />
              <ZCheckbox
                label="Mid Impact"
                disabled={busy}
                value={news.mid}
                onChange={(c) => setNews({ ...news, mid: c })}
              />
              <ZCheckbox
                label="High Impact"
                disabled={busy}
                value={news.high}
                onChange={(c) => setNews({ ...news, high: c })}
              />
            </Stack>
          )}
          <Divider />
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#1F2937",
                }}
              >
                Profit range
              </Typography>
            </Stack>
            <ZSwitch
              disabled={busy}
              checked={showProfitRange}
              onChange={(e, c) => setShowProfitRange(c)}
            />
          </Stack>
          {showProfitRange && (
            <ZRangeSlider
              disabled={busy}
              min={0}
              max={2000}
              value={profitRange}
              onChange={(e, v) => setProfitRange(v as any)}
            />
          )}
          {showProfitRange && (
            <Stack direction={"row"} spacing={"8px"}>
              <ZInput
                disabled={busy}
                fullWidth
                placeholder="0.00"
                text={profitRange[0].toString()}
                onUpdate={(t) =>
                  setProfitRange([parseFloat(t || "0"), profitRange[0]])
                }
              />
              <ZInput
                disabled={busy}
                onUpdate={(t) =>
                  setProfitRange([profitRange[0], parseFloat(t || "0")])
                }
                fullWidth
                placeholder="0.00"
                text={profitRange[1].toString()}
              />
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: "20px" }}>
        <Button
          disabled={busy}
          fullWidth
          variant="contained"
          color="secondary"
          onClick={() => props.onClose?.()}
        >
          Cancel
        </Button>
        <Button
          onClick={save}
          disabled={busy}
          fullWidth
          variant="contained"
          color="primary"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
