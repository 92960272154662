import * as React from "react";

const IcInformation: React.FC<React.SVGProps<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#fff"
      fillOpacity="0.01"
      d="M0 0h16v16H0z"
      style={{ mixBlendMode: "multiply" }}
    ></path>
    <path
      fill="#6B7280"
      d="M8.5 11V7h-2v1h1v3H6v1h4v-1zM8 4a.75.75 0 1 0 0 1.5A.75.75 0 0 0 8 4"
    ></path>
    <path
      fill="#6B7280"
      d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14M8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2"
    ></path>
  </svg>
);

export default IcInformation;
