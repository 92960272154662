import React from "react";

function IcSettings() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.147 1.333h-.294A1.333 1.333 0 006.52 2.667v.12a1.333 1.333 0 01-.667 1.153l-.286.167a1.333 1.333 0 01-1.334 0l-.1-.054a1.333 1.333 0 00-1.82.487l-.146.253a1.333 1.333 0 00.486 1.82l.1.067a1.333 1.333 0 01.667 1.147v.34a1.333 1.333 0 01-.667 1.16l-.1.06a1.333 1.333 0 00-.486 1.82l.146.253a1.333 1.333 0 001.82.487l.1-.054a1.334 1.334 0 011.334 0l.286.167a1.333 1.333 0 01.667 1.153v.12a1.333 1.333 0 001.333 1.334h.294a1.334 1.334 0 001.333-1.334v-.12a1.333 1.333 0 01.667-1.153l.286-.167a1.334 1.334 0 011.334 0l.1.054a1.333 1.333 0 001.82-.487l.146-.26a1.334 1.334 0 00-.486-1.82l-.1-.053a1.334 1.334 0 01-.667-1.16v-.334a1.333 1.333 0 01.667-1.16l.1-.06a1.334 1.334 0 00.486-1.82l-.146-.253a1.333 1.333 0 00-1.82-.487l-.1.054a1.334 1.334 0 01-1.334 0l-.286-.167a1.333 1.333 0 01-.667-1.153v-.12a1.333 1.333 0 00-1.333-1.334z"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 10a2 2 0 100-4 2 2 0 000 4z"
      ></path>
    </svg>
  );
}

export default IcSettings;
