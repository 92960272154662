import React from "react";

function IcNewspaper() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath="url(#clip0_1078_8136)"
      >
        <path d="M2.667 14.667h10.666a1.333 1.333 0 001.334-1.334V2.667a1.334 1.334 0 00-1.334-1.334h-8A1.333 1.333 0 004 2.667v10.666a1.333 1.333 0 01-1.333 1.334zm0 0a1.333 1.333 0 01-1.334-1.334v-6C1.333 6.6 1.933 6 2.667 6H4M12 9.333H6.667M10 12H6.667"></path>
        <path d="M6.667 4H12v2.667H6.667V4z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1078_8136">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IcNewspaper;
