import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import IcClose from "../assets/icons/IcClose";
import { IconButtonOutlined } from "../components/IconButtonOutlined";
import IcNewsFolder from "../assets/icons/IcNewsFolder";
import { NewsEvent } from "../types/NewsEvents";
import moment from "moment";
import { ChevronRight } from "@mui/icons-material";
import IcBlackFolder from "../assets/icons/IcBlackFolder";
import safari from "../assets/images/safari.png";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { RtmGetEventHistory } from "../core/rtm";

export default function NewsEventDialog(props: {
  onClose?: () => void;
  event: NewsEvent;
}) {
  const news = props.event;
  const theme = useTheme();

  const [showHistory, setShowHistory] = useState(false);
  const [showSpecs, setShowSpecs] = useState(false);
  const [showRelatedStories, setShowRelatedStories] = useState(false);
  const [history, setHistory] = useState<NewsEvent[]>([]);

  async function loadHistory() {
    try {
      const _history = await RtmGetEventHistory(props.event.id);
      setHistory(_history);
      console.log("Loaded historical events: ", _history);
    } catch (err: any) {
      enqueueSnackbar("Error loading historical events for this event. ", {
        variant: "error",
      });
      console.error("Error loading historical events. ", err);
    }
  }

  useEffect(() => {
    loadHistory();
  }, []);

  return (
    <Dialog
      open
      fullScreen
      onClose={props.onClose}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        padding: "8px",
        [`& .${dialogClasses.paper}`]: {
          borderRadius: "24px",
          background: "#FFF",
        },
      }}
    >
      <DialogTitle sx={{ p: "20px" }}>
        {/* Main stack as Row: With Title and Dialog Buttons (i.e. close button) */}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          spacing={"18px"}
        >
          {/* The event title */}
          <Stack spacing={"4px"}>
            {/* Row 1: Folder Icon and Event Title  */}
            <Stack direction={"row"} alignItems={"center"} spacing={"10px"}>
              <SvgIcon
                sx={{
                  background:
                    news.impact === "High"
                      ? theme.palette.error.main
                      : news.impact === "Medium"
                      ? theme.palette.warning.main
                      : theme.palette.info.main,
                  borderRadius: "4px",
                  p: "4px",
                  height: "36px",
                  width: "36px",
                }}
              >
                <IcNewsFolder />
              </SvgIcon>
              <Typography
                sx={{
                  fontSize: 24,
                  fontWeight: 600,
                  color: theme.palette.text.primary,
                  textWrap: "wrap",
                }}
              >
                {news.title}
              </Typography>
              <Stack
                sx={{
                  border: "1px solid #E5E7EB",
                  borderRadius: "6px",
                  p: "6px",
                }}
              >
                {news.country}
              </Stack>
            </Stack>

            {/* Row 2: Event Time and Date  */}
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 500,
                color: "#6B7280",
              }}
            >
              {moment.unix(news.date).format("MMMM DD, yyyy \\at HH:mm")}
            </Typography>
          </Stack>

          {/* Dialog Actions: Close, Maximize, etc.  */}
          <Stack direction={"row"} alignItems={"center"}>
            <IconButtonOutlined onClick={props.onClose}>
              <IcClose />
            </IconButtonOutlined>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={"16px"}>
          <Paper elevation={1}>
            <Stack
              direction={"row"}
              spacing={"8px"}
              sx={{ padding: "8px", alignSelf: "stretch" }}
            >
              <Paper elevation={2} sx={{ flex: 1 }}>
                <Stack padding={"16px"}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "16px",
                      color: "#6B7280",
                    }}
                  >
                    Actual
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#EF4444",
                      letterSpacing: "0.9px",
                    }}
                  >
                    -1.3%
                  </Typography>
                </Stack>
              </Paper>
              {props.event.forecast && (
                <Paper elevation={2} sx={{ flex: 1 }}>
                  <Stack padding={"16px"}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "16px",
                        color: "#6B7280",
                      }}
                    >
                      Forecast
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        color: "#020617",
                        letterSpacing: "0.9px",
                      }}
                    >
                      {props.event.forecast}
                    </Typography>
                  </Stack>
                </Paper>
              )}
              {props.event.previous && (
                <Paper elevation={2} sx={{ flex: 1 }}>
                  <Stack padding={"16px"}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "16px",
                        color: "#6B7280",
                      }}
                    >
                      Previous
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        color:
                          parseFloat(props.event.previous!) > 0
                            ? "#298c6b"
                            : "#EF4444",
                        letterSpacing: "0.9px",
                      }}
                    >
                      {props.event.previous}
                    </Typography>
                  </Stack>
                </Paper>
              )}
            </Stack>
          </Paper>

          {/*  history  */}
          <Accordion
            expanded={showHistory}
            onChange={(e, x) => setShowHistory(x)}
          >
            <AccordionSummary
              expandIcon={
                <ChevronRight
                  sx={{
                    transform: "rotate(-90deg)",
                  }}
                />
              }
            >
              History
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={"8px"}>
                {history.map((h, i) => (
                  <Paper elevation={2} sx={{ padding: "12px" }}>
                    <Stack padding={"6px"}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "600",
                          lineHeight: "16px",
                          color: "#6B7280",
                          textTransform: "uppercase",
                        }}
                      >
                        {moment.unix(h.date).format("MMM DD, YYYY")}
                      </Typography>
                    </Stack>

                    <Stack>
                      <Stack
                        justifyContent={"space-between"}
                        direction={"row"}
                        sx={{
                          background: "#F8FAFC",
                          padding: "6px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "16px",
                            color: "#6B7280",
                          }}
                        >
                          Actual
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: "400",
                            lineHeight: "24px",
                            color: "#EF4444",
                            letterSpacing: "0.9px",
                          }}
                        >
                          -1.3%
                        </Typography>{" "}
                      </Stack>

                      {h.forecast && (
                        <Stack
                          justifyContent={"space-between"}
                          direction={"row"}
                          sx={{ padding: "6px" }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              lineHeight: "16px",
                              color: "#6B7280",
                            }}
                          >
                            Forcast
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "18px",
                              fontWeight: "400",
                              lineHeight: "24px",
                              color: "#020617",
                              letterSpacing: "0.9px",
                            }}
                          >
                            {h.forecast}
                          </Typography>
                        </Stack>
                      )}
                      {h.previous && (
                        <Stack
                          justifyContent={"space-between"}
                          direction={"row"}
                          sx={{
                            background: "#F8FAFC",
                            padding: "6px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              lineHeight: "16px",
                              color: "#6B7280",
                            }}
                          >
                            Previous
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "18px",
                              fontWeight: "400",
                              lineHeight: "24px",
                              color:
                                parseFloat(h.previous!) > 0
                                  ? "#298c6b"
                                  : "#EF4444",
                              letterSpacing: "0.9px",
                            }}
                          >
                            {h.previous}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                  </Paper>
                ))}
                {history.length === 0 && (
                  <Typography
                    textAlign={"center"}
                    fontSize={10}
                    sx={{ opacity: 0.8 }}
                  >
                    No past events found with matching title.
                  </Typography>
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={showRelatedStories}
            onChange={(e, x) => setShowRelatedStories(x)}
          >
            <AccordionSummary
              expandIcon={
                <ChevronRight
                  sx={{
                    transform: "rotate(-90deg)",
                  }}
                />
              }
            >
              Related Stories
            </AccordionSummary>
            <AccordionDetails>
              {news.related?.map((n, i) => (
                <Paper elevation={2} sx={{ padding: "12px" }}>
                  <Stack
                    direction={"row"}
                    flex={1}
                    justifyContent={"space-between"}
                  >
                    <Stack gap={"4px"}>
                      <Stack
                        gap={"8px"}
                        direction={"row"}
                        justifyContent={"start"}
                        sx={{ padding: "0" }}
                      >
                        <IcBlackFolder />
                        <Typography
                          sx={{
                            color: "#020617",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                          display={"inline"}
                        >
                          {n.title}
                        </Typography>
                      </Stack>
                      {n.description && (
                        <Typography
                          sx={{
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "20px",
                            cursor: "pointer",
                          }}
                        >
                          {n.description}
                        </Typography>
                      )}
                      <Typography
                        sx={{
                          color: "#2563EB",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          cursor: "pointer",
                        }}
                      >
                        {n.url}
                      </Typography>
                    </Stack>
                    {n.thumbnail && (
                      <Stack
                        sx={{
                          width: "76px",
                          height: "76px",
                        }}
                      >
                        <img
                          src={n.thumbnail}
                          alt=""
                          style={{ width: "100%", height: "100%" }}
                        />
                      </Stack>
                    )}
                  </Stack>
                </Paper>
              ))}
              {(!news.related || news.related.length === 0) && (
                <Typography
                  textAlign={"center"}
                  fontSize={10}
                  sx={{ opacity: 0.8 }}
                >
                  No related links have been added for this event.
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={showSpecs} onChange={(e, x) => setShowSpecs(x)}>
            <AccordionSummary
              expandIcon={
                <ChevronRight
                  sx={{
                    transform: "rotate(-90deg)",
                  }}
                />
              }
            >
              Specs
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={"8px"}>
                {/* Source/URL  */}
                {news.url && (
                  <Stack
                    sx={{
                      background: "#FFF",
                      p: "12px",
                      borderRadius: "8px",
                      border: "0.5px solid #E5E7EB",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#6B7280",
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      Source
                    </Typography>
                    <Stack
                      direction={"row"}
                      onClick={() => window.open(news.url, "_blank")}
                      sx={{
                        width: "100%",
                        p: "6px",
                        cursor: "pointer",
                        background: "#F8FAFC",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography sx={{ flex: 1, color: "#020617" }}>
                        {props.event.url}
                      </Typography>

                      <IcNewsFolder fill="#020617" />
                    </Stack>
                  </Stack>
                )}

                {/* Measures  */}
                {news.specs?.measures && (
                  <Stack
                    sx={{
                      background: "#FFF",
                      p: "12px",
                      borderRadius: "8px",
                      border: "0.5px solid #E5E7EB",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#6B7280",
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      Measures
                    </Typography>
                    <Stack
                      direction={"row"}
                      sx={{
                        width: "100%",
                        p: "6px",
                        background: "#F8FAFC",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography sx={{ flex: 1, color: "#020617" }}>
                        {news.specs.measures}
                      </Typography>
                    </Stack>
                  </Stack>
                )}

                {/* Effects  */}
                {news.specs?.effects && (
                  <Stack
                    sx={{
                      background: "#FFF",
                      p: "12px",
                      borderRadius: "8px",
                      border: "0.5px solid #E5E7EB",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#6B7280",
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      Usual Effects
                    </Typography>
                    <Stack
                      direction={"row"}
                      sx={{
                        width: "100%",
                        p: "6px",
                        background: "#F8FAFC",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography sx={{ flex: 1, color: "#020617" }}>
                        {news.specs.effects}
                      </Typography>
                    </Stack>
                  </Stack>
                )}

                {/* Next Release */}
                {news.specs?.next && (
                  <Stack
                    sx={{
                      background: "#FFF",
                      p: "12px",
                      borderRadius: "8px",
                      border: "0.5px solid #E5E7EB",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#6B7280",
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      Next Release
                    </Typography>
                    <Stack
                      direction={"row"}
                      sx={{
                        width: "100%",
                        p: "6px",
                        background: "#F8FAFC",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography sx={{ flex: 1, color: "#020617" }}>
                        {moment.unix(news.specs.next).format("MMMM DD, YYYY")}
                      </Typography>
                    </Stack>
                  </Stack>
                )}

                {/* Importance */}
                {news.specs?.importance && (
                  <Stack
                    sx={{
                      background: "#FFF",
                      p: "12px",
                      borderRadius: "8px",
                      border: "0.5px solid #E5E7EB",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#6B7280",
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      Why traders care
                    </Typography>
                    <Stack
                      direction={"row"}
                      sx={{
                        width: "100%",
                        p: "6px",
                        background: "#F8FAFC",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography sx={{ flex: 1, color: "#020617" }}>
                        {news.specs.importance}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
