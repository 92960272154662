import React from "react";

function ImgQuantifyFx() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="36"
      fill="none"
      viewBox="0 0 128 36"
    >
      <path
        fill="#000"
        d="M39.733 25.724h-3.2V24.18c-.795 1.035-2.18 1.697-3.746 1.697-2.928 0-5.04-1.85-5.04-5.198v-7.157h3.179v6.718c0 1.96 1.113 3.018 2.792 3.018 1.725 0 2.815-1.058 2.815-3.018v-6.718h3.2v12.2zM47.792 13.323c2.02 0 3.405.925 4.154 1.938v-1.74h3.2v12.203h-3.2V23.94c-.75 1.057-2.18 1.982-4.177 1.982-3.179 0-5.72-2.534-5.72-6.344 0-3.81 2.541-6.255 5.743-6.255zm.816 2.709c-1.702 0-3.314 1.233-3.314 3.546 0 2.313 1.612 3.634 3.314 3.634 1.748 0 3.338-1.277 3.338-3.589 0-2.313-1.59-3.591-3.338-3.591zM67.063 18.984c0-1.96-1.112-3.018-2.792-3.018-1.725 0-2.837 1.057-2.837 3.018v6.74h-3.178V13.52h3.178v1.52c.818-1.036 2.18-1.697 3.79-1.697 2.929 0 5.017 1.85 5.017 5.22v7.158h-3.178v-6.738zM73.76 16.054h-1.498v-2.533h1.498v-3.018h3.2v3.018h2.815v2.533H76.96v5.903c0 .814.34 1.167 1.338 1.167h1.475v2.6h-1.997c-2.405 0-4.018-.992-4.018-3.79v-5.88h.002zM81.727 10.261c0-1.012.818-1.806 1.953-1.806 1.113 0 1.952.794 1.952 1.806 0 1.013-.84 1.806-1.953 1.806-1.136 0-1.952-.793-1.952-1.806zm.34 3.26h3.179v12.202h-3.179V13.521zM88.718 16.054h-1.43v-2.533h1.43v-.617c0-2.995 1.748-4.383 5.288-4.294v2.6c-1.543-.044-2.065.44-2.065 1.805v.507h2.201v2.534h-2.201v9.669h-3.224v-9.67zM104.857 13.521h3.382l-7.786 17.973H97.07l2.724-6.08-5.04-11.893h3.565l3.247 8.524 3.291-8.524zM113.092 8.502h4.532v1.153h-3.061v1.828h2.346v1.132h-2.346v3.01h-1.471V8.503zM121.473 13.227l-1.44 2.399h-1.662l2.314-3.593-2.345-3.531h1.693l1.587 2.388 1.43-2.388h1.662l-2.303 3.582 2.345 3.542h-1.693l-1.588-2.399z"
      ></path>
      <path
        fill="#231F20"
        d="M14.176 4.5c-6.037 0-10.93 4.748-10.93 10.605 0 5.202 3.86 9.53 8.954 10.433v-3.412c-3.183-.841-5.523-3.666-5.523-7.021 0-4.019 3.358-7.277 7.5-7.277 4.14 0 7.498 3.258 7.498 7.277 0 1.294-.35 2.511-.96 3.566l2.49 2.415a10.342 10.342 0 001.9-5.98C25.105 9.25 20.213 4.5 14.176 4.5z"
      ></path>
      <path
        fill="#231F20"
        d="M14.191 25.697V15.092l10.9 10.55h-4.824l-2.647-2.569v2.624h-3.429z"
      ></path>
    </svg>
  );
}

export default ImgQuantifyFx;
