import { Button, IconButton, Stack } from "@mui/material";
import "@blocknote/core/fonts/inter.css";
import "@blocknote/mantine/style.css";
import IcAttachment from "../assets/icons/attachment";
import { useState } from "react";
import { locales } from "@blocknote/core";
import { BlockNoteView } from "@blocknote/mantine";
import { FormattingToolbar, useCreateBlockNote } from "@blocknote/react";

export default function AddTradeActivity(props: {
  onSave?: (
    activity: string,
    attachments?: { name: string; type: string; size: number; url: string }[]
  ) => void;
}) {
  const [busy, setBusy] = useState(false);
  const editor = useCreateBlockNote({
    dictionary: {
      ...locales.en,
      placeholders: {
        ...locales.en.placeholders,
        default: "Write a note...",
      },
    },
  });

  async function save() {
    setBusy(true);
    try {
      const _txt = await editor.blocksToFullHTML(editor.document);
      await props.onSave?.(_txt);
      editor.removeBlocks(editor.document);
    } catch (err: any) {}
    setBusy(false);
  }

  // Renders the editor instance using a React component.
  return (
    <Stack
      sx={{
        border: "1px solid #2563EB",
        padding: "12px",
        borderRadius: "8px",
        width: "100%",
        ":focus-within": {
          boxShadow: "0px 0px 0px 4px rgba(59, 130, 246, 0.25)",
        },
      }}
      spacing={"8px"}
    >
      {/* Editor */}
      <BlockNoteView
        editor={editor}
        formattingToolbar={false}
        sideMenu={false}
        slashMenu={false}
      >
        <FormattingToolbar />
      </BlockNoteView>

      {/* Buttons */}
      <Stack
        direction={"row"}
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconButton>
          <IcAttachment />
        </IconButton>
        <Button
          sx={{
            background: "#2563EB",
            color: "#fff",
            fontSize: "14px",
            fontWeight: "600",
            padding: " 8px 16px",
            ":hover": {
              background: "#256eaB",
            },
          }}
          onClick={save}
          disabled={busy}
        >
          Send
        </Button>
      </Stack>
    </Stack>
  );
}
