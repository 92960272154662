import React from "react";

function IC_LIGHT_THEME() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="29"
      viewBox="0 0 39 29"
      fill="none"
    >
      <path
        d="M4 1C2.34315 1 1 2.34315 1 4V25C1 26.6569 2.34315 28 4 28H30C34.4183 28 38 24.4183 38 20V4C38 2.34315 36.6569 1 35 1H4Z"
        fill="white"
      />
      <path
        d="M4 1C2.34315 1 1 2.34315 1 4V25C1 26.6569 2.34315 28 4 28H30C34.4183 28 38 24.4183 38 20V4C38 2.34315 36.6569 1 35 1H4Z"
        stroke="white"
        stroke-width="2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.7442 5.18677V7.79536C33.7442 8.51272 33.0695 9.09966 32.2448 9.09966H6.7552C5.93054 9.09966 5.25581 8.51272 5.25581 7.79536V5.18677C5.25581 4.46941 5.93054 3.88248 6.7552 3.88248H32.2448C33.0695 3.88248 33.7442 4.46941 33.7442 5.18677ZM23.2485 13.0125V23.4469C23.2485 24.1643 22.5737 24.7512 21.7491 24.7512H6.7552C5.93054 24.7512 5.25581 24.1643 5.25581 23.4469V13.0125C5.25581 12.2952 5.93054 11.7082 6.7552 11.7082H21.7491C22.5737 11.7082 23.2485 12.2952 23.2485 13.0125Z"
        fill="#E3E3E3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.7441 13.0125V23.4469C33.7441 24.1643 33.0694 24.7512 32.2447 24.7512H27.7465C26.9219 24.7512 26.2471 24.1643 26.2471 23.4469V13.0125C26.2471 12.2952 26.9219 11.7083 27.7465 11.7083H32.2447C33.0694 11.7083 33.7441 12.2952 33.7441 13.0125Z"
        fill="#E3E3E3"
      />
    </svg>
  );
}

export default IC_LIGHT_THEME;
