import React from "react";

function IcTime() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        fillOpacity="0.01"
        style={{ mixBlendMode: "multiply" }}
        d="M0 0H16V16H0z"
      ></path>
      <path
        fill="currentColor"
        d="M8 15A7 7 0 118 1a7 7 0 010 14zM8 2a6 6 0 100 12A6 6 0 008 2z"
      ></path>
      <path
        fill="currentColor"
        d="M10.295 11L7.5 8.205V3.5h1v4.29l2.5 2.505-.705.705z"
      ></path>
    </svg>
  );
}

export default IcTime;
