import React from "react";

function IcInbox() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath="url(#clip0_1078_8174)"
      >
        <path d="M14.133 5.6c.334.253.534.647.534 1.067v6.666a1.334 1.334 0 01-1.334 1.334H2.667a1.334 1.334 0 01-1.334-1.334V6.667A1.333 1.333 0 011.867 5.6l5.333-4a1.333 1.333 0 011.6 0l5.333 4z"></path>
        <path d="M14.667 6.667l-5.98 3.8a1.294 1.294 0 01-1.374 0l-5.98-3.8"></path>
      </g>
      <defs>
        <clipPath id="clip0_1078_8174">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IcInbox;
