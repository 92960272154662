import * as React from "react";

const IcOpenTradeGroup: React.FC<React.SVGProps<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#fff"
      fillOpacity="0.01"
      d="M0 0h16v16H0z"
      style={{ mixBlendMode: "multiply" }}
    ></path>
    <path
      fill="#161616"
      stroke="#1F2937"
      strokeWidth="0.5"
      d="M13.5 13.75h.25v-7.5h.5v7.25a.75.75 0 0 1-.75.75h-11a.75.75 0 0 1-.75-.75v-11a.75.75 0 0 1 .75-.75h7.25v.5h-7.5v11.5H13.5Zm1.25-11a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
    ></path>
  </svg>
);

export default IcOpenTradeGroup;
