import { styled, Switch } from "@mui/material";
import Check from "../assets/icons/IcSwitchCheck.svg";
import Uncheck from "../assets/icons/IcSwitchUncheck.svg";

export const ZSwitch = styled(Switch)((props) => ({
  padding: 0,
  height: "28px",
  width: "50px",
  "& .MuiSwitch-track": {
    borderRadius: 50,
    background: "#F3F4F6",
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 24,
      height: 24,
    },
    "&::before": {
      backgroundImage: `url(${Uncheck})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      left: "2px",
      zIndex: 10,
    },
    "&::after": {
      backgroundImage: `url(${Check})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      right: "2px",
      zIndex: 10,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 24,
    height: 24,
    background: "#FFF",
    zIndex: 5,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${Uncheck})`,
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: "2px 4px",
  },
  "& .Mui-checked": {
    "& + .MuiSwitch-track": {
      backgroundColor: "#1C1D20 !important",
      opacity: "1 !important",
    },
    "& .MuiSwitch-thumb": {
      backgroundImage: `url(${Check})`,
    },
  },
}));
