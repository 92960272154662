import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  dialogClasses,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { IconButtonOutlined } from "../components/IconButtonOutlined";
import IcClose from "../assets/icons/IcClose";
import moment, { Moment } from "moment";
import { SYMBOLS } from "../assets/static";
import { ChevronRight } from "@mui/icons-material";
import { useState } from "react";
import { RtmGetLinkPreview } from "../core/rtm/user";
import TradeDetailsDialog, { LinkPreview } from "./TradeDetails";
import IcArrowRight from "../assets/icons/arrow-right";
import { useModal } from "mui-modal-provider";
import { TSTrade } from "../types/TSTrade";

export default function TradingDayDetails(props: {
  onClose?: () => void;
  date: Moment;
  trades: TSTrade[];
}) {
  const { showModal } = useModal();
  const pnl = props.trades.reduce((pv, cv, ci) => pv + cv.profit, 0);
  const theme = useTheme();
  const [expandedTrades, setExpandedTrades] = useState<{
    [key: string]: boolean;
  }>({});
  const [tradingViewLinkPreviews, setTradingViewLinkPreviews] = useState<{
    [key: string]: {
      title: string;
      description: string;
      thumbnail: string;
      url: string;
    };
  }>({});

  async function loadLinkPreview(trade: TSTrade) {
    if (trade.tradingViewLink && !tradingViewLinkPreviews[trade.id]) {
      const _preview = await RtmGetLinkPreview(trade.tradingViewLink);
      if (_preview) {
        setTradingViewLinkPreviews({
          ...tradingViewLinkPreviews,
          [`${trade.id}`]: {
            ..._preview,
            url: trade.tradingViewLink,
          },
        });
      }
    }
  }

  function KeyValuePair(props: { label: string; value: any }) {
    return (
      <Stack
        sx={{
          p: "6px",
          borderRadius: "8px",
        }}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          sx={{
            color: "#6B7280",
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          {props.label}
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            color: "#020617",
          }}
        >
          {props.value}
        </Typography>
      </Stack>
    );
  }

  function RenderTrade(trade: TSTrade) {
    return (
      <Accordion
        expanded={expandedTrades[trade.id]}
        onChange={(e, ex) => {
          setExpandedTrades({
            ...expandedTrades,
            [`${trade.id}`]: ex,
          });
          loadLinkPreview(trade);
        }}
        sx={{
          bgcolor: "#FFF",
        }}
      >
        <AccordionSummary>
          <Stack spacing={"4px"} sx={{ width: "100%" }}>
            {/* Flag - Symbol - Type + Vol */}
            <Stack alignItems={"center"} direction={"row"} spacing={"10px"}>
              {/* Flag */}
              <Typography
                sx={{
                  color: "#020617",
                  fontSize: 14,
                  fontWeight: 600,
                }}
              >
                {trade.symbol}
              </Typography>
              <Stack
                sx={{
                  border: "1px solid #E5E7EB",
                  borderRadius: "6px",
                  px: "4px",
                  height: "20px",
                  background: trade.type.includes("sell")
                    ? "#FEE2E2"
                    : "rgba(41, 140, 107, 0.15)",
                }}
              >
                <Typography
                  sx={{
                    color: trade.type.includes("sell") ? "#991B1B" : "#10B981",
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  {trade.type.includes("sell") ? "Sell " : "Buy "} {trade.lots}
                </Typography>
              </Stack>
              <Stack flex={1} />
              <ChevronRight
                sx={{
                  transform: expandedTrades[trade.id]
                    ? "rotate(-90deg)"
                    : "rotate(90deg)",
                }}
              />
            </Stack>
            <Typography
              sx={{
                color: "#6B7280",
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              {SYMBOLS[trade.symbol.split(".")[0]]}
            </Typography>
            <Stack
              sx={{ pt: "12px", width: "100%" }}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                sx={{ color: "#6B72807A", fontSize: 12, fontWeight: 400 }}
              >
                {/* Time of trade */}
                {moment(trade.open_time).format("hh:MM:ss | DD.mm.yyyy")}
              </Typography>
              <Typography
                sx={{
                  color: trade.profit > 0 ? "#298C6B" : "#EF4444",
                  fontSize: 14,
                  fontWeight: 600,
                }}
              >
                {trade.profit > 0 ? "+" : "-"}$ {Math.abs(trade.profit)}
              </Typography>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Paper
            elevation={2}
            sx={{
              mt: "8px",
              p: "12px",
              background: "#FFF",
              "& .MuiStack-root:nth-child(even)": {
                background: "#F8FAFC",
              },
            }}
          >
            <KeyValuePair label={"Entry price"} value={trade.open_price} />
            {trade.close_time && (
              <KeyValuePair label={"Exit price"} value={trade.close_price} />
            )}
            <KeyValuePair
              label={"Time entry"}
              value={moment(trade?.open_time!).format("HH:mm:ss | DD.MM.yyyy")}
            />
            {trade.close_time && (
              <KeyValuePair
                label={"Time exit"}
                value={moment(trade.close_time).format("HH:mm:ss | DD.MM.yyyy")}
              />
            )}
            {Boolean(trade.stop_loss) && (
              <KeyValuePair label={"Stop loss"} value={trade.stop_loss} />
            )}
            {Boolean(trade.take_profit) && (
              <KeyValuePair label={"Take profit"} value={trade.take_profit} />
            )}
            {trade.comment && (
              <KeyValuePair label={"Comment"} value={trade.comment} />
            )}
            {trade.swap > 0 && (
              <KeyValuePair label={"Swap"} value={trade.swap} />
            )}
            {trade.commission > 0 && (
              <KeyValuePair label={"Commission"} value={trade.swap} />
            )}

            {trade.state && (
              <KeyValuePair label={"State"} value={trade.state} />
            )}
          </Paper>
          {tradingViewLinkPreviews[trade.id] && (
            <LinkPreview preview={tradingViewLinkPreviews[trade.id]} />
          )}
          <Button
            endIcon={<IcArrowRight />}
            size="small"
            sx={{ mt: "16px" }}
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => {
              const _modal = showModal(TradeDetailsDialog, {
                trade: trade,
                onClose() {
                  _modal.destroy();
                },
              });
            }}
          >
            More details
          </Button>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Dialog
      open
      fullScreen
      onClose={props.onClose}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        padding: "8px",
        [`& .${dialogClasses.paper}`]: {
          borderRadius: "24px",
          background: "#FFF",
          minWidth: "522px",
        },
      }}
    >
      <DialogTitle sx={{ p: "20px" }}>
        {/* Main stack as Row: With Title and Dialog Buttons (i.e. close button) */}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          spacing={"18px"}
        >
          {/* The event title */}
          <Stack spacing={"4px"}>
            <Typography
              sx={{
                fontSize: 24,
                fontWeight: 600,
                color: theme.palette.text.primary,
                textWrap: "wrap",
              }}
            >
              {props.date.format("MMMM DD, yyyy")}
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 500,
                color: "#6B7280",
              }}
            >
              You took {props.trades.length} trades today.
            </Typography>
          </Stack>

          {/* Dialog Actions: Close, Maximize, etc.  */}
          <Stack direction={"row"} alignItems={"center"} spacing={"6px"}>
            <IconButtonOutlined onClick={props.onClose}>
              <IcClose />
            </IconButtonOutlined>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 0 }}>
        <Paper elevation={1} sx={{ height: "100%", border: "none", p: "20px" }}>
          <Stack direction={"row"}>
            <Paper elevation={2} sx={{ flex: 1, p: "16px", maxWidth: "200px" }}>
              <Stack spacing={"4px"}>
                <Typography
                  sx={{
                    color: "#6B7280",
                    fontSize: 12,
                    fontWeight: 400,
                  }}
                >
                  Today's P&L
                </Typography>
                <Typography
                  sx={{
                    color: pnl > 0 ? "#298C6B" : "#EF4444",
                    fontSize: 18,
                    fontWeight: 400,
                  }}
                >
                  {pnl > 0 ? "+" : "-"}$ {Math.abs(pnl).toFixed(1)}
                </Typography>
              </Stack>
            </Paper>
          </Stack>

          {/* Show All trades */}
          <Stack spacing={"8px"} sx={{ pt: "8px" }}>
            {props.trades.map((t) => RenderTrade(t))}
          </Stack>
        </Paper>
      </DialogContent>
    </Dialog>
  );
}
