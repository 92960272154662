import { Stack, Tooltip, Typography } from "@mui/material";
import { useState } from "react";

interface DualCircularProgressProps {
  valuePrimary: number; // Progress value (0-100)
  valueSecondary: number;
  size: number; // Diameter of the progress bar
  strokeWidth: number; // Thickness of the stroke
  trackPrimaryColor?: string; // Color of the track
  valuePrimaryColor?: string; // Color of the progress bar
  valueSecondaryColor: string;
  trackSecondaryColor: string;
  tooltipLabelPrimary?: string;
  tooltipValuePrimary?: string;
  tooltipValueSecondary?: string;
  tooltipLabelSecondary?: string;
}
interface TooltipData {
  x: number;
  y: number;
  label: string;
  value: string;
  visible: boolean;
}

export default function DualCircularProgress(props: DualCircularProgressProps) {
  // Primary Progress Bar
  const radius = (props.size - props.strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (props.valuePrimary / 100) * circumference;

  // Secondary Progress Bar
  const secondarySize = props.size - props.strokeWidth * 3;
  const secondaryRadius = (secondarySize - props.strokeWidth) / 2;
  const secondaryCircumference = 2 * Math.PI * secondaryRadius;
  const secondaryOffset =
    secondaryCircumference -
    (props.valueSecondary / 100) * secondaryCircumference;

  // Dots
  // Primary dot's position
  const angle = (props.valuePrimary / 100) * 360; // Progress angle in degrees
  const radians = (angle - 90) * (Math.PI / 180); // Convert to radians and adjust for starting at top
  const dotX = props.size / 2 + radius * Math.cos(radians);
  const dotY = props.size / 2 + radius * Math.sin(radians);
  // Secondary dot's position
  const secondaryDotAngle = (props.valueSecondary / 100) * 360;
  const secondaryRadians = (secondaryDotAngle - 90) * (Math.PI / 180);

  const secondaryDotX =
    props.size / 2 + secondaryRadius * Math.cos(secondaryRadians);
  const secondaryDotY =
    props.size / 2 + secondaryRadius * Math.sin(secondaryRadians);

  const [hovered, setHovered] = useState<"primary" | "secondary">();
  const [tooltip, setTooltip] = useState<TooltipData>({
    x: 0,
    y: 0,
    label: "",
    value: "",
    visible: false,
  });
  const handleMouseMove = (
    e: React.MouseEvent<SVGCircleElement>,
    label: string,
    value: string
  ) => {
    const svgElement = e.currentTarget;
    const rect = svgElement.getBoundingClientRect();

    setTooltip({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
      label: label,
      value: value,
      visible: true,
    });
  };

  const handleMouseLeave = () => {
    setTooltip({ x: 0, y: 0, label: "", value: "", visible: false });
    setHovered(undefined);
  };

  return (
    <Tooltip
      enterDelay={0}
      followCursor
      title={
        tooltip.value ? (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={"12px"}
          >
            <Typography sx={{ fontWeight: 400, fontSize: 14 }}>
              {tooltip.label}
            </Typography>
            <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
              {tooltip.value}
            </Typography>
          </Stack>
        ) : (
          ""
        )
      }
    >
      <svg width={props.size} height={props.size}>
        {/* Track Primary */}
        <circle
          cx={props.size / 2}
          cy={props.size / 2}
          r={radius}
          fill="none"
          key={hovered as any}
          stroke={props.trackPrimaryColor}
          strokeWidth={props.strokeWidth}
        />

        {/* Progress Primary */}
        <circle
          onMouseMove={(e) => {
            if (props.tooltipLabelPrimary && props.tooltipValuePrimary) {
              handleMouseMove(
                e,
                props.tooltipLabelPrimary,
                props.tooltipValuePrimary
              );
            }
          }}
          onMouseEnter={() => setHovered("primary")}
          onMouseLeave={handleMouseLeave}
          cx={props.size / 2}
          cy={props.size / 2}
          r={radius}
          fill="none"
          stroke={props.valuePrimaryColor}
          strokeWidth={props.strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap={"round"}
          style={{
            transition: "all 0.35s",
            transformOrigin: "center",
            transform: "rotate(-90deg)",
            opacity: hovered === "secondary" ? 0.5 : 1,
          }}
        />

        {/* Dot Primary */}
        <circle
          cx={dotX}
          cy={dotY}
          r={2} // Make the dot size proportional to the stroke width
          fill={props.trackPrimaryColor}
        />

        {/* Track Secondary */}
        <circle
          cx={props.size / 2}
          cy={props.size / 2}
          r={secondaryRadius}
          fill="none"
          stroke={props.trackSecondaryColor}
          strokeWidth={props.strokeWidth}
        />

        {/* Progress Secondary */}
        <circle
          onMouseMove={(e) => {
            if (props.tooltipLabelSecondary && props.tooltipValueSecondary) {
              handleMouseMove(
                e,
                props.tooltipLabelSecondary,
                props.tooltipValueSecondary
              );
            }
          }}
          onMouseEnter={() => setHovered("secondary")}
          onMouseLeave={handleMouseLeave}
          cx={props.size / 2}
          cy={props.size / 2}
          r={secondaryRadius}
          fill="none"
          stroke={props.valueSecondaryColor}
          strokeWidth={props.strokeWidth}
          strokeDasharray={secondaryCircumference}
          strokeDashoffset={secondaryOffset}
          strokeLinecap={"round"}
          style={{
            transition: "all 0.35s",
            transformOrigin: "center",
            transform: "rotate(-90deg)",
            opacity: hovered === "primary" ? 0.5 : 1,
          }}
        />

        {/* Secondary Dot */}
        <circle
          cx={secondaryDotX}
          cy={secondaryDotY}
          r={2} // Make the dot size proportional to the stroke width
          fill={props.trackSecondaryColor}
        />
      </svg>
    </Tooltip>
  );
}
