import * as React from "react";

const IcWhitePaper: React.FC<React.SVGProps<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#fff"
      fillOpacity="0.01"
      d="M0 0h24v24H0z"
      style={{ mixBlendMode: "multiply" }}
    ></path>
    <path
      fill="currentColor"
      d="m16.5 20.385-1.943-1.943L13.5 19.5l3 3 6-6-1.058-1.057zM12 12.75H6.75v1.5H12zM15.75 9h-9v1.5h9zM15.75 5.25h-9v1.5h9z"
    ></path>
    <path
      fill="currentColor"
      d="M12 22.5H4.5c-.827 0-1.5-.673-1.5-1.5V3c0-.827.673-1.5 1.5-1.5H18c.827 0 1.5.673 1.5 1.5v11.25H18V3H4.5v18H12z"
    ></path>
  </svg>
);

export default IcWhitePaper;
