import * as React from "react";

const IcChevronRight: React.FC<React.SVGProps<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    fill="none"
    viewBox="0 0 20 21"
  >
    <path
      fill="#fff"
      fillOpacity="0.01"
      d="M0 0h20v20H0z"
      style={{ mixBlendMode: "multiply" }}
      transform="translate(0 .5)"
    ></path>
    <path
      fill="#1F2937"
      d="M13.75 10.5 7.5 16.75l-.875-.875L12 10.5 6.625 5.125 7.5 4.25z"
    ></path>
  </svg>
);

export default IcChevronRight;
