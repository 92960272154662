import React from "react";

function IC_DARK_THEME() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="29"
      viewBox="0 0 40 29"
      fill="none"
    >
      <path
        d="M1.5 27V28H2.5H29.5C34.4706 28 38.5 23.9706 38.5 19V4C38.5 2.34315 37.1569 1 35.5 1H4.5C2.84315 1 1.5 2.34315 1.5 4V27Z"
        fill="#121212"
      />
      <path
        d="M1.5 27V28H2.5H29.5C34.4706 28 38.5 23.9706 38.5 19V4C38.5 2.34315 37.1569 1 35.5 1H4.5C2.84315 1 1.5 2.34315 1.5 4V27Z"
        stroke="#121212"
        stroke-width="2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.2442 5.18677V7.79536C34.2442 8.51272 33.5695 9.09966 32.7448 9.09966H7.2552C6.43054 9.09966 5.75581 8.51272 5.75581 7.79536V5.18677C5.75581 4.46941 6.43054 3.88248 7.2552 3.88248H32.7448C33.5695 3.88248 34.2442 4.46941 34.2442 5.18677ZM23.7485 13.0125V23.4469C23.7485 24.1643 23.0737 24.7512 22.2491 24.7512H7.2552C6.43054 24.7512 5.75581 24.1643 5.75581 23.4469V13.0125C5.75581 12.2952 6.43054 11.7082 7.2552 11.7082H22.2491C23.0737 11.7082 23.7485 12.2952 23.7485 13.0125Z"
        fill="#353535"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.2441 13.0125V23.4469C34.2441 24.1643 33.5693 24.7512 32.7447 24.7512H28.2465C27.4219 24.7512 26.7471 24.1643 26.7471 23.4469V13.0125C26.7471 12.2952 27.4219 11.7083 28.2465 11.7083H32.7447C33.5693 11.7083 34.2441 12.2952 34.2441 13.0125Z"
        fill="#353535"
      />
    </svg>
  );
}

export default IC_DARK_THEME;
