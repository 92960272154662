import React from "react";

function IcStickyNote() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.333 2h-7A1.333 1.333 0 002 3.333v9.334C2 13.4 2.6 14 3.333 14h9.334A1.334 1.334 0 0014 12.667v-7L10.333 2z"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 2v4h4"
      ></path>
    </svg>
  );
}

export default IcStickyNote;
