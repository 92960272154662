import * as React from "react";

const IcChecklist: React.FC<React.SVGProps<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M22.5 6H12v1.5h10.5zM4.5 7.943 2.558 6 1.5 7.058l3 3 6-6L9.443 3zM22.5 16.5H12V18h10.5zM4.5 18.443 2.558 16.5 1.5 17.558l3 3 6-6L9.443 13.5z"
    ></path>
  </svg>
);

export default IcChecklist;
