import * as React from "react";

const IcArrowRight: React.FC<React.SVGProps<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="currentColor"
      fillOpacity="0.01"
      d="M0 0h20v20H0z"
      style={{ mixBlendMode: "multiply" }}
    ></path>
    <path
      fill="#161616"
      stroke="#1F2937"
      d="M11.245 4.453 16.793 10l-5.543 5.543-.186-.185 4.383-4.38.855-.853H3v-.25h13.298l-.85-.853-4.38-4.396z"
    ></path>
  </svg>
);

export default IcArrowRight;
