import { Radio, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { TradingPlan } from "../types/TradingPlan";

export default function TradingPlanEditor(props: {
  plan: TradingPlan;
  onChange?: (plan: TradingPlan) => void;
}) {
  const [items, setItems] = useState<{ text: string; checked: boolean }[]>(
    props.plan.items || []
  );

  useEffect(() => {
    if (items.length === 0) {
      items.push({ checked: false, text: "" });
      setItems([...items]);
    }
    props.onChange?.({ ...props.plan, items: items });
  }, [items]);

  return (
    <Stack
      sx={{
        borderRadius: "12px",
        border: "1px solid ##E5E7EB",
        p: "12px",
        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
        height: "auto",
        background: "#FFF",
        overflow: "visible",
        "& .MuiStack-root:nth-child(even)": {
          background: "#F8FAFC",
        },
      }}
    >
      {items.map((item, index) => (
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{
            overflow: "visible",
            height: "auto",
            borderRadius: "8px",
          }}
        >
          <Radio
            checked={item.checked}
            onClick={(e) => {
              items[index].checked = !items[index].checked;
              setItems([...items]);
            }}
          />
          <Typography
            autoFocus
            component={"input"}
            sx={{
              color: item.checked ? "#6B7280" : "#020617",
              fontSize: 14,
              fontWeight: 500,
              textDecoration: item.checked ? "line-through" : "none",
              border: "none",
              background: "transparent",
              outline: "none",
              flex: 1,
              textWrap: "wrap",
              resize: "none",
              height: "auto",
              overflow: "visible",
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                items.push({ checked: false, text: "" });
                setItems([...items]);
              } else if (e.key === "Backspace") {
                if (!items[index].text) {
                  items.splice(index, 1);
                  setItems([...items]);
                }
              }
            }}
            onChange={(e) => {
              items[index].text = e.currentTarget.value;
              setItems([...items]);
            }}
            value={item.text}
          />
        </Stack>
      ))}
    </Stack>
  );
}
