import { Paper, Stack, Typography, useTheme } from "@mui/material";

export default function TradingJournalStatCard(props: {
  heading: string;
  value: string;
  negative?: boolean;
}) {
  const theme = useTheme();

  return (
    <Paper elevation={2} sx={{ p: "16px", flex: 1 }}>
      <Stack>
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 400,
            color: theme.palette.grey["400"],
          }}
        >
          {props.heading}
        </Typography>
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 400,
            color: props.negative
              ? theme.palette.error.main
              : theme.palette.success.main,
          }}
        >
          {props.value}
        </Typography>
      </Stack>
    </Paper>
  );
}
