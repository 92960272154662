import * as React from "react";

const IcNotificationCounter: React.FC<React.SVGProps<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M20.25 9v11.25H3.75V3.75H15v-1.5H3.75a1.5 1.5 0 0 0-1.5 1.5v16.5a1.5 1.5 0 0 0 1.5 1.5h16.5a1.5 1.5 0 0 0 1.5-1.5V9z"
    ></path>
    <path
      fill="currentColor"
      d="M19.875 6.75a2.625 2.625 0 1 0 0-5.25 2.625 2.625 0 0 0 0 5.25"
    ></path>
  </svg>
);

export default IcNotificationCounter;
