import * as React from "react";

const IcMaximize: React.FC<React.SVGProps<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="currentColor"
      d="M12.5 1.25V2.5h4.116L11.25 7.864l.884.886L17.5 3.384V7.5h1.25V1.25zM8.75 12.135l-.88-.885-5.37 5.366V12.5H1.25v6.25H7.5V17.5H3.384z"
    ></path>
  </svg>
);

export default IcMaximize;
