import moment from "moment";
import IcTrendUp from "../../../assets/icons/IcTrendUp";
import Chart from "react-apexcharts";
import TradingJournalProgressCard from "../../../components/TradingJournalProgressCard";
import TradingJournalStatCard from "../../../components/TradingJournalStatCard";

import { useState, useEffect, useCallback, useMemo } from "react";
import {
  Divider,
  Paper,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import Calendar from "../../../components/Calendar";
import { enqueueSnackbar } from "notistack";
import { RtmGetTradingJournalStats } from "../../../core/rtm/user";
import TradingJournalProgressCardEx from "../../../components/TradingJournalProgressCard2";
import IcTrendDown from "../../../assets/icons/IcTrendDown";
import ZSelect, { ZSelectOption } from "../../../components/ZSelect";
import { aSelectedAccount, aUser } from "../../../core/state";
import { useRecoilState } from "recoil";
import IcCalendar from "../../../assets/icons/IcCalendar";
import IcQuantify from "../../../assets/icons/IcQuantify";

export default function SectionTradingJournal() {
  const theme = useTheme();
  const [profile] = useRecoilState(aUser);
  const [selectedAccount, setSelectedAccount] =
    useRecoilState(aSelectedAccount);
  const [busy, setBusy] = useState(false);
  const [series, setSeries] = useState<any>([
    {
      name: "pnl",
      data: [
        {
          x: "Jan",
          y: 31,
        },
        {
          x: "Feb",
          y: 40,
        },
        {
          x: "Mar",
          y: 28,
        },
        {
          x: "Apr",
          y: 51,
        },
        {
          x: "May",
          y: 42,
        },
        {
          x: "Jun",
          y: 56,
        },
        {
          x: "Jul",
          y: 33,
        },
      ],
      group: "apexcharts-axis-0",
      zIndex: 0,
    },
    {
      name: "equity",
      group: "apexcharts-axis-0",
      data: [
        {
          x: "Jan",
          y: "50",
        },
        {
          x: "Feb",
          y: "60",
        },
        {
          x: "Mar",
          y: "40",
        },
        {
          x: "Apr",
          y: "40",
        },
        {
          x: "May",
          y: "40",
        },
        {
          x: "Jun",
          y: "50",
        },
        {
          x: "Jul",
          y: "55",
        },
      ],
      zIndex: 1,
    },
  ]);
  const [trades, setTrades] = useState<{
    wins: number;
    losses: number;
    count: number;
    days: number;
    percentByTrades: number;
    percentByDays: number;
    averageWin: number;
    averageLoss: number;
    winningDays: number;
    losingDays: number;
  }>();
  const [netPnl, setNetPnl] = useState(0);
  const [profitFactor, setProfitFactor] = useState(0);
  const [totalPnl, setTotalPnl] = useState(0);
  const [previous, setPrevious] = useState(0);
  const [period, setPeriod] = useState<
    "3-months" | "6-months" | "1-year" | "30-days"
  >("3-months");

  const accounts = useMemo(() => {
    if (!profile) return [];
    const _options: ZSelectOption[] = [
      { label: "All", value: "all", heading: "Live & Demo" },
    ];
    for (let _ac of profile.accounts || []) {
      _options.push({ label: _ac.login, value: _ac.id, heading: "Live" });
    }
    return _options;
  }, [profile]);

  const load = useCallback(async () => {
    try {
      setBusy(true);
      const _data = await RtmGetTradingJournalStats(period);
      console.log(_data);
      setSeries([
        {
          name: "Growth",
          data: _data.chart.growth,
          group: "apexcharts-axis-0",
          zIndex: 0,
        },
        {
          name: "Equity",
          data: _data.chart.equity,
          group: "apexcharts-axis-0",
          zIndex: 1,
        },
      ]);
      setTrades(_data.trades);
      setNetPnl(_data.netPnl || 0);
      setTotalPnl(_data.totalPnl || 0);
      setPrevious(_data.chart.previous);
      setProfitFactor(_data.profitFactor || 0);
    } catch (err: any) {
      enqueueSnackbar("Error loading stats. Please try later. ", {
        variant: "error",
      });
      console.error("Error loading data for trading journal. ", err);
    }
    setBusy(false);
  }, [period]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Paper
      elevation={0}
      sx={{
        m: "8px",
        flex: 1,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack sx={{ px: "24px", py: "16px" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack>
            <Typography variant="sectionHeading">Trading Journal</Typography>
            <Typography variant="sectionDescription">
              {moment().format("dddd, MMMM DD, YYYY")}
            </Typography>
          </Stack>
          <ZSelect
            sx={{ minWidth: "200px" }}
            options={accounts}
            value={selectedAccount}
            onChange={(o) => setSelectedAccount(o.value as any)}
            startAdornment={
              <Stack
                sx={{
                  background: "#F8FAFC",
                  border: "1px solid #E5E7EB",
                  borderRadius: "4px",
                  minHeight: "32px",
                  minWidth: "32px",
                }}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <IcQuantify />
              </Stack>
            }
          />
        </Stack>
      </Stack>

      <Divider />

      <Stack flex={1} sx={{ overflow: "auto" }}>
        {/* Chart */}
        <Stack sx={{ px: "24px", py: "16px" }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "28px",
                letterSpacing: "0.09px",
                color: theme.palette.text.primary,
              }}
            >
              Cumulative P&L
            </Typography>
            <ZSelect
              options={[
                { label: "Last 30 Days", value: "30-days" },
                { label: "Last 3 Months", value: "3-months" },
                { label: "Last 6 Months", value: "6-months" },
                { label: "Year", value: "1-year" },
              ]}
              value={period}
              onChange={(o) => setPeriod(o.value as any)}
              startAdornment={
                <SvgIcon sx={{ p: "4px", height: "24px", width: "24px" }}>
                  <IcCalendar />
                </SvgIcon>
              }
            />
          </Stack>

          <Stack sx={{ py: "16px" }}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 300,
                lineHeight: "32px",
                letterSpacing: "0.12px",
                color: theme.palette.text.primary,
              }}
            >
              ${totalPnl.toFixed(0)}
            </Typography>
            {previous > 0 && (
              <Stack direction={"row"} alignItems={"center"}>
                <IcTrendUp />
                <Typography color={theme.palette.success.main}>
                  +{previous.toFixed(1)}%{" "}
                  <span style={{ color: theme.palette.grey["600"] }}>
                    previous {period}
                  </span>
                </Typography>
              </Stack>
            )}
            {previous < 0 && (
              <Stack direction={"row"} alignItems={"center"}>
                <IcTrendDown />
                <Typography color={theme.palette.error.main}>
                  -{previous.toFixed(1)}%{" "}
                  <span style={{ color: theme.palette.grey["600"] }}>
                    previous {period}
                  </span>
                </Typography>
              </Stack>
            )}

            <Chart
              type="area"
              series={series}
              width={"100%"}
              height={"324px"}
              options={{
                chart: {
                  animations: {
                    enabled: true,
                  },
                  background: "#0000",
                  fontFamily: "Manrope",
                  type: "area",
                },
                xaxis: {
                  type: "datetime",
                },
                yaxis: {
                  decimalsInFloat: 0,
                  labels: {
                    formatter(val, opts) {
                      return `$${val}`;
                    },
                  },
                },
                tooltip: {
                  y: {
                    formatter(val, opts) {
                      return "$" + val.toFixed(0);
                    },
                  },
                },
                fill: {
                  type: "gradient",
                  gradient: {
                    shade: "light",
                    type: "vertical",
                    opacityFrom: 0.6,
                    opacityTo: 0,
                    colorStops: [
                      {
                        opacity: 0.2,
                        offset: 0,
                        color: "#298C6B",
                      },
                      {
                        opacity: 0,
                        offset: 100,
                        color: "#FFFFFF",
                      },
                    ],
                  },
                },
                grid: {
                  strokeDashArray: 6,
                  padding: {
                    right: 24,
                    left: 24,
                  },
                },
                legend: {
                  showForSingleSeries: true,
                  horizontalAlign: "right",
                  fontSize: "14px",
                  offsetX: 0,
                  offsetY: 0,
                  markers: {
                    size: 8,
                    fillColors: ["#298C6B", "#BBDAD0"],
                  },
                },
                stroke: {
                  curve: "straight",
                  lineCap: "round",
                  width: 2,
                  colors: ["#298C6B", "#BBDAD0"],
                  fill: {
                    type: "solid",
                    opacity: 0.85,
                    gradient: {
                      shade: "light",
                      type: "vertical",
                      shadeIntensity: 0.5,
                      inverseColors: false,
                      opacityFrom: 0.65,
                      opacityTo: 0.5,
                      stops: [0, 100, 100],
                      colorStops: [],
                    },
                  },
                },
                dataLabels: {
                  enabled: false,
                },
              }}
            />
          </Stack>
        </Stack>

        <Paper elevation={1} sx={{ p: "8px", mx: "24px", my: "12px" }}>
          <Stack direction={"row"} spacing={"8px"}>
            <TradingJournalProgressCard
              heading="Winning % by Trades"
              primaryLabel="Wins"
              primaryValue={trades?.wins || 0}
              secondaryLabel="Losses"
              secondaryValue={trades?.losses || 0}
              value={trades?.percentByTrades + "%"}
              centerHeading={trades?.count.toString() || "0"}
              centerText="Trades"
            />
            <TradingJournalProgressCardEx
              heading="Winning % by Days"
              primaryLabel="Wins"
              primaryValue={
                ((trades?.winningDays || 0) / (trades?.days || 100)) * 100
              }
              secondaryLabel="Losses"
              secondaryValue={
                ((trades?.losingDays || 0) / (trades?.days || 100)) * 100
              }
              value={trades?.percentByDays.toString() + "%"}
              centerHeading={trades?.days.toString()}
              centerText="Days"
              tooltipPrimaryLabel="Winning Days"
              tooltipPrimaryValue={trades?.winningDays.toString()}
              tooltipSecondaryLabel="Losing Days"
              tooltipSecondaryValue={trades?.losingDays.toString()}
            />
            <Stack flex={1} spacing={"8px"}>
              <Stack
                flex={1}
                direction={"row"}
                sx={{ width: "100%" }}
                spacing={"8px"}
              >
                <TradingJournalStatCard
                  heading="Net P&L"
                  value={"$" + netPnl.toFixed(0)}
                />
                <TradingJournalStatCard
                  heading="Total P&L"
                  value={"$" + totalPnl.toFixed(0)}
                />
                <TradingJournalStatCard
                  heading="Profit Factor"
                  value={profitFactor?.toString()}
                />
              </Stack>
              <Stack
                flex={1}
                direction={"row"}
                sx={{ width: "100%" }}
                spacing={"8px"}
              >
                <TradingJournalStatCard
                  heading="Average Winning Trade"
                  value={"$" + (trades?.averageWin.toFixed(0) || "0")}
                />
                <TradingJournalStatCard
                  heading="Average Losing Trade"
                  negative
                  value={"$-" + (trades?.averageLoss.toFixed(0) || "0")}
                />
              </Stack>
            </Stack>
          </Stack>
        </Paper>

        <Divider />

        <Stack sx={{ p: "24px" }}>
          <Calendar />
        </Stack>
      </Stack>
    </Paper>
  );
}
