import { Alert, AlertTitle, Button, Stack, Typography } from "@mui/material";
import { IMG_CUBES, IMG_LOGO } from "../../assets/images";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { applyActionCode } from "firebase/auth";
import { FbAuth } from "../../core/firebase";
import { RestPostVerification } from "../../core/rest/accounts";
import { enqueueSnackbar } from "notistack";
import { motion } from "framer-motion";
import IcAlertTriangle from "../../assets/icons/alert-triangle";
import IcArrowLeft from "../../assets/icons/arrow-left";
import IcCheckCircle from "../../assets/icons/check";
import ImgQuantifyFx from "../../assets/images/quantifyfx";

export default function PageEmailVerification() {
  const [verified, setVerified] = useState(false);
  const loc = useLocation();
  const code = loc.state?.code;
  const email = loc.state?.email;
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(false);

  async function verifyEmail() {
    try {
      setBusy(true);
      // Proceed.
      await applyActionCode(FbAuth, code!);
      // Send back notification
      // await RestPostVerification(email!);
      // Send verification email.
      enqueueSnackbar("Your account has been confirmed.", {
        variant: "success",
      });
      // IF continue url is set, we navigatte.
      setVerified(true);
    } catch (err: any) {
      enqueueSnackbar(
        "Error verifying your account. Please contact administrators.",
        {
          variant: "error",
        }
      );
      console.log(err);
      setError(true);
    }
    setBusy(false);
  }

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <Stack
      sx={{
        minHeight: "100vh",
        transition: "all .2s",
        opacity: 1,
        minWidth: "100vw",
        backgroundPosition: "center",
      }}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Stack sx={{ position: "fixed", top: 32, left: 32 }}>
        <ImgQuantifyFx />
      </Stack>
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ maxWidth: "1280px", width: "100%" }}
        direction={"row"}
      >
        {/* The signup form */}
        <Stack
          spacing="24px"
          sx={{
            p: "20px",
            borderRadius: "20px",
            width: "440px",
          }}
        >
          <Stack spacing={"16px"}>
            <Stack alignItems={"center"}>
              <Typography
                variant="h1"
                sx={{ fontSize: "36px", fontWeight: "800", lineHeight: "44px" }}
              >
                Verifying Email
              </Typography>
              <Typography variant="subtitle1">
                Please wait while we confirm your email address...
              </Typography>
            </Stack>

            <Stack spacing="20px">
              <AnimatePresence>
                {error && (
                  <motion.div
                    key={"loginError"}
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                  >
                    <Alert
                      variant="filled"
                      color="error"
                      icon={<IcAlertTriangle />}
                    >
                      <AlertTitle>Unable to verify</AlertTitle>
                      <Typography>
                        We were unable to verify your email address. Please try
                        again.
                      </Typography>
                    </Alert>
                  </motion.div>
                )}
                {verified && (
                  <motion.div
                    key={"emailVerified"}
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                  >
                    <Alert
                      variant="filled"
                      color="success"
                      icon={<IcCheckCircle />}
                    >
                      <AlertTitle>Email Verified</AlertTitle>
                      <Typography>
                        Your email address has been verified. You may now log in
                        to your account.
                      </Typography>
                    </Alert>
                  </motion.div>
                )}
              </AnimatePresence>
            </Stack>
            <Button
              color="secondary"
              variant="text"
              disabled={busy}
              startIcon={<IcArrowLeft />}
              href="/login"
            >
              Back to log in
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
