import { useState, useEffect } from "react";

// material-ui
import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// third-party
import moment from "moment";

// assets
import AppstoreOutlined from "@ant-design/icons/AppstoreOutlined";
import LayoutOutlined from "@ant-design/icons/LayoutOutlined";
import { IconButton, ToggleButton, ToggleButtonGroup } from "@mui/material";
import IcArrowLeft from "../../assets/icons/arrow-left";
import IcArrowRight from "../../assets/icons/arrow-right";
import { IconButtonOutlined } from "../IconButtonOutlined";
import IcFilter from "../../assets/icons/IcFilter";
import { useModal } from "mui-modal-provider";
import TradingJournalFilterDialog, {
  TradingJournalFilter,
} from "../../dialogs/TradingJournalFilter";

// constant
const viewOptions = [
  {
    label: "Month",
    value: "dayGridMonth",
    icon: AppstoreOutlined,
  },
  {
    label: "Week",
    value: "timeGridWeek",
    icon: LayoutOutlined,
  },
];

// ==============================|| CALENDAR - TOOLBAR ||============================== //

export interface ToolbarProps {
  date: number | Date;
  view: string;
  onClickNext: () => void;
  onClickPrev: () => void;
  onClickToday: () => void;
  onChangeView: (s: string) => void;
  onChangeFilter?: (filter: TradingJournalFilter) => void;
}

export default function Toolbar({
  date,
  view,
  onClickNext,
  onClickPrev,
  onClickToday,
  onChangeView,
  onChangeFilter,
}: ToolbarProps) {
  const matchDownSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [viewFilter, setViewFilter] = useState(viewOptions);
  const { showModal } = useModal();

  useEffect(() => {
    if (matchDownSM) {
      const filter = viewOptions.filter(
        (item) => item.value !== "dayGridMonth" && item.value !== "timeGridWeek"
      );
      setViewFilter(filter);
    } else {
      setViewFilter(viewOptions);
    }
  }, [matchDownSM]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ pb: "20px" }}
    >
      {/* Date & Today Button */}
      <Stack direction={"row"} spacing="12px" alignItems={"center"}>
        <Button
          variant="outlined"
          onClick={onClickToday}
          color="secondary"
          size={"small"}
          sx={{ height: "36px" }}
        >
          Today
        </Button>
        <IconButton onClick={onClickPrev}>
          <IcArrowLeft />
        </IconButton>
        <IconButton onClick={onClickNext}>
          <IcArrowRight />
        </IconButton>
        <Typography
          sx={{ fontSize: 18, fontWeight: 600, lineHeight: "28px" }}
          color="text.primary"
        >
          {moment(date).format("MMMM")}
          <span style={{ opacity: 0.48 }}> {moment(date).format("yyyy")}</span>
        </Typography>
      </Stack>

      {/* Filter, Toggles, and More */}
      <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
        <ToggleButtonGroup
          exclusive
          value={view}
          onChange={(e, v) => onChangeView(v || view)}
        >
          {viewFilter.map((vf) => (
            <ToggleButton value={vf.value}>{vf.label}</ToggleButton>
          ))}
        </ToggleButtonGroup>
        <IconButtonOutlined
          onClick={() => {
            const _modal = showModal(TradingJournalFilterDialog, {
              onClose(filter) {
                _modal.destroy();
                if (filter) {
                  // Send update to calendar
                  onChangeFilter?.(filter);
                }
              },
            });
          }}
        >
          <IcFilter />
        </IconButtonOutlined>
      </Stack>
    </Stack>
  );
}
