import { Slider, SliderProps, SliderThumb, styled } from "@mui/material";

const RangeSlider = styled(Slider)(({ theme }) => ({
  color: "#1C1D20",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 27,
    width: 27,
    backgroundColor: "#fff",
    border: "3px solid #1C1D20",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    color: "#A4A4A4",
    opacity: 1,
    height: 3,
    ...theme.applyStyles("dark", {
      color: "#A4A4A4",
      opacity: undefined,
    }),
  },
}));

interface RangeSliderThumbComponentProps
  extends React.HTMLAttributes<unknown> {}

function RangeSliderThumbComponent(props: RangeSliderThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="rangeslider-bar" />
      <span className="rangeslider-bar" />
      <span className="rangeslider-bar" />
    </SliderThumb>
  );
}

export default function ZRangeSlider(props: SliderProps) {
  return (
    <RangeSlider
      slots={{ thumb: RangeSliderThumbComponent }}
      getAriaLabel={(index) =>
        index === 0 ? "Minimum price" : "Maximum price"
      }
      defaultValue={[20, 40]}
      {...props}
    />
  );
}
