import * as React from "react";

const IcNewsFolder: React.FC<React.SVGProps<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 20 20"
  >
    <path
      fill={props.fill || "#FFF"}
      d="M6.981 3.75 9.12 5.881l.362.369H17.5v10h-15V3.75zm0-1.25H2.5a1.25 1.25 0 0 0-1.25 1.25v12.5A1.25 1.25 0 0 0 2.5 17.5h15a1.25 1.25 0 0 0 1.25-1.25v-10A1.25 1.25 0 0 0 17.5 5H10L7.869 2.869A1.25 1.25 0 0 0 6.98 2.5"
    ></path>
  </svg>
);

export default IcNewsFolder;
