import * as React from "react";

const IcArrowLeft: React.FC<React.SVGProps<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="currentColor"
      fillOpacity="0.01"
      d="M0 0h20v20H0z"
      style={{ mixBlendMode: "multiply" }}
    ></path>
    <path
      fill="#161616"
      stroke="#1F2937"
      d="M4.894 9.875H17v.25H3.688l.852.853 4.384 4.39-.174.175L3.207 10 8.75 4.457l.174.174-4.384 4.39-.852.854z"
    ></path>
  </svg>
);

export default IcArrowLeft;
