import {
  Divider,
  Paper,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { enqueueSnackbar } from "notistack";
import { useRecoilState } from "recoil";
import { aSelectedAccount, aUser } from "../../../core/state";
import LoadingOverlay from "../../../components/LoadingOverlay";
import ZSelect, { ZSelectOption } from "../../../components/ZSelect";
import moment from "moment";
import IcQuantify from "../../../assets/icons/IcQuantify";
import IcCalendar from "../../../assets/icons/IcCalendar";
import IcTrendUp from "../../../assets/icons/IcTrendUp";
import IcTrendDown from "../../../assets/icons/IcTrendDown";
import Chart from "react-apexcharts";

export default function PageUserDashboard() {
  const theme = useTheme();
  const [selectedAccount, setSelectedAccount] =
    useRecoilState(aSelectedAccount);
  const [profile, setProfile] = useRecoilState(aUser);
  const [period, setPeriod] = useState<
    "3-months" | "6-months" | "1-year" | "30-days"
  >("3-months");
  const [previous, setPrevious] = useState(0);

  const [busy, setBusy] = useState(false);
  const navigate = useNavigate();

  const accounts = useMemo(() => {
    if (!profile) return [];
    const _options: ZSelectOption[] = [
      { label: "All", value: "all", heading: "Live & Demo" },
    ];
    for (let _ac of profile.accounts || []) {
      _options.push({ label: _ac.login, value: _ac.id, heading: _ac.name });
    }
    return _options;
  }, [profile]);

  const load = useCallback(async () => {
    try {
      setBusy(true);
    } catch (err: any) {
      enqueueSnackbar("Error loading stats. Please try later. ", {
        variant: "error",
      });
      console.error("Error loading data for trading journal. ", err);
    }
    setBusy(false);
  }, [period]);

  useEffect(() => {
    load();
  }, [load]);

  if (busy || !profile) {
    return <LoadingOverlay />;
  }

  return (
    <Paper
      elevation={0}
      sx={{
        m: "8px",
        flex: 1,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack sx={{ px: "24px", py: "16px" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack>
            <Typography variant="sectionHeading">
              Hey, {profile.firstName}!
            </Typography>
            <Typography variant="sectionDescription">
              {moment().format("dddd, MMMM DD, YYYY")}
            </Typography>
          </Stack>
          <ZSelect
            sx={{ minWidth: "200px" }}
            options={accounts}
            value={selectedAccount}
            onAdd={() => {
              navigate("/connect-account");
            }}
            onChange={(o) => setSelectedAccount(o.value as any)}
            startAdornment={
              <Stack
                sx={{
                  background: "#F8FAFC",
                  border: "1px solid #E5E7EB",
                  borderRadius: "4px",
                  minHeight: "32px",
                  minWidth: "32px",
                }}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <IcQuantify />
              </Stack>
            }
          />
        </Stack>
      </Stack>

      <Divider />
      <Stack flex={1} sx={{ overflow: "auto" }}>
        {/* Chart */}
        <Stack sx={{ px: "24px", py: "16px" }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "28px",
                letterSpacing: "0.09px",
                color: theme.palette.text.primary,
              }}
            >
              Quantify Score
            </Typography>
            <ZSelect
              options={[
                { label: "Last 30 Days", value: "30-days" },
                { label: "Last 3 Months", value: "3-months" },
                { label: "Last 6 Months", value: "6-months" },
                { label: "Year", value: "1-year" },
              ]}
              value={period}
              onChange={(o) => setPeriod(o.value as any)}
              startAdornment={
                <SvgIcon sx={{ p: "4px", height: "24px", width: "24px" }}>
                  <IcCalendar />
                </SvgIcon>
              }
            />
          </Stack>

          <Stack sx={{ py: "16px" }}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 300,
                lineHeight: "32px",
                letterSpacing: "0.12px",
                color: theme.palette.text.primary,
              }}
            >
              0
            </Typography>
            {previous > 0 && (
              <Stack direction={"row"} alignItems={"center"}>
                <IcTrendUp />
                <Typography color={theme.palette.success.main}>
                  +{previous.toFixed(1)}%{" "}
                  <span style={{ color: theme.palette.grey["600"] }}>
                    previous {period}
                  </span>
                </Typography>
              </Stack>
            )}
            {previous < 0 && (
              <Stack direction={"row"} alignItems={"center"}>
                <IcTrendDown />
                <Typography color={theme.palette.error.main}>
                  -{previous.toFixed(1)}%{" "}
                  <span style={{ color: theme.palette.grey["600"] }}>
                    previous {period}
                  </span>
                </Typography>
              </Stack>
            )}

            <Chart
              type="area"
              series={[]}
              width={"100%"}
              height={"324px"}
              options={{
                chart: {
                  animations: {
                    enabled: true,
                  },
                  background: "#0000",
                  fontFamily: "Manrope",
                  type: "area",
                },
                xaxis: {
                  type: "datetime",
                },
                yaxis: {
                  decimalsInFloat: 0,
                  labels: {
                    formatter(val, opts) {
                      return `$${val}`;
                    },
                  },
                },
                tooltip: {
                  y: {
                    formatter(val, opts) {
                      return "$" + val.toFixed(0);
                    },
                  },
                },
                fill: {
                  type: "gradient",
                  gradient: {
                    shade: "light",
                    type: "vertical",
                    opacityFrom: 0.6,
                    opacityTo: 0,
                    colorStops: [
                      {
                        opacity: 0.2,
                        offset: 0,
                        color: "#298C6B",
                      },
                      {
                        opacity: 0,
                        offset: 100,
                        color: "#FFFFFF",
                      },
                    ],
                  },
                },
                grid: {
                  strokeDashArray: 6,
                  padding: {
                    right: 24,
                    left: 24,
                  },
                },
                legend: {
                  showForSingleSeries: true,
                  horizontalAlign: "right",
                  fontSize: "14px",
                  offsetX: 0,
                  offsetY: 0,
                  markers: {
                    size: 8,
                    fillColors: ["#298C6B", "#BBDAD0"],
                  },
                },
                stroke: {
                  curve: "straight",
                  lineCap: "round",
                  width: 2,
                  colors: ["#298C6B", "#BBDAD0"],
                  fill: {
                    type: "solid",
                    opacity: 0.85,
                    gradient: {
                      shade: "light",
                      type: "vertical",
                      shadeIntensity: 0.5,
                      inverseColors: false,
                      opacityFrom: 0.65,
                      opacityTo: 0.5,
                      stops: [0, 100, 100],
                      colorStops: [],
                    },
                  },
                },
                dataLabels: {
                  enabled: false,
                },
              }}
            />
          </Stack>
        </Stack>

        <Paper elevation={1} sx={{ p: "8px", mx: "24px", my: "12px" }}>
          <Stack direction={"row"} spacing={"8px"}></Stack>
        </Paper>

        <Divider />
      </Stack>
    </Paper>
  );
}
