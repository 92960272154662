import React, { useEffect, useState } from "react";
import {
  Box,
  FilledInput,
  FormHelperText,
  IconButton,
  Stack,
  SvgIcon,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import IcEye from "../assets/icons/eye";
import IcEyeOff from "../assets/icons/eye-off";

export default function ZInput(props: {
  label?: string;
  important?: boolean;
  text?: string;
  /**
   * Set default text, for use in uncontrolled mode.
   */
  defaultText?: string;
  onUpdate?: (text: string, strength?: number) => void;
  placeholder?: string;
  startIcon?: React.ReactNode;
  buttonIcon?: any;
  buttonClick?: () => void;
  onBlur?: () => void;
  errorText?: string;
  helperText?: string;
  password?: boolean;
  onReturn?: any;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  startAdornment?: any;
  endAdornment?: any;
  autoFocus?: boolean;
  disabled?: boolean;
  showStrength?: boolean;
  /**
   * Applied to the root element (Stack)
   */
  sx?: SxProps;
}) {
  const theme = useTheme();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  function toggleVisibility() {
    setPasswordVisible(!passwordVisible);
  }

  /**
   * Returns the strength of the password on the scale of 4, for use in the password input component.
   * @param password
   * @returns
   */
  function checkPasswordStrength(password: string): number {
    let strength = 0;

    if (password.length < 8) return 0;

    // Check length
    if (password.length >= 8) {
      strength += 1;
    }

    // Check for uppercase and lowercase letters
    if (/[A-Z]/.test(password) && /[a-z]/.test(password)) {
      strength += 1;
    }

    // Check for numbers
    if (/[0-9]/.test(password)) {
      strength += 1;
    }

    // Check for symbols
    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)) {
      strength = 4; // Set strength to 4 if a symbol is present
    } else {
      strength = Math.min(strength, 3); // Cap strength at 3 if no symbol is present
    }

    return strength;
  }

  useEffect(() => {
    if (props.showStrength) {
      const _strength = checkPasswordStrength(props.text || "");
      setPasswordStrength(_strength);
    }
  }, [props.text, props.showStrength]);

  return (
    <Stack sx={{ width: "100%", height: "100%", ...props.sx }} spacing={"8px"}>
      {props.label && (
        <Typography
          fontWeight={"600"}
          fontSize={"14px"}
          sx={{ display: "flex" }}
        >
          {props.label}
          {props.important && <Typography color="error">*</Typography>}
        </Typography>
      )}
      <FilledInput
        onKeyDown={(ke) => {
          if (ke.key === "Enter" && props.onReturn) props.onReturn();
        }}
        onBlur={props.onBlur}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
        autoFocus={props.autoFocus}
        multiline={props.multiline}
        defaultValue={props.defaultText}
        rows={props.rows}
        {...(props.text ? { value: props.text } : {})}
        onChange={(t) =>
          props.onUpdate?.(t.currentTarget.value, passwordStrength)
        }
        disableUnderline
        hiddenLabel
        size="small"
        type={props.password && !passwordVisible ? "password" : "text"}
        error={Boolean(props.errorText)}
        sx={{
          borderColor: props.errorText ? "#EF4444" : undefined,
        }}
        inputProps={{
          style: {
            paddingRight: "14px",
            paddingLeft: "14px",
            paddingTop: "10px",
            paddingBottom: "10px",
          },
        }}
        placeholder={props.placeholder}
        {...(props.startIcon
          ? {
              startAdornment: (
                <Stack
                  sx={{
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  {props.startIcon}
                </Stack>
              ),
            }
          : {})}
        {...(props.buttonIcon
          ? {
              endAdornment: (
                <IconButton onClick={props.buttonClick}>
                  <props.buttonIcon />
                </IconButton>
              ),
            }
          : {
              ...(props.password
                ? {
                    endAdornment: (
                      <Tooltip
                        title={
                          passwordVisible ? "Hide password" : "Show password"
                        }
                      >
                        <IconButton onClick={toggleVisibility}>
                          {passwordVisible ? (
                            <SvgIcon>
                              <IcEyeOff />
                            </SvgIcon>
                          ) : (
                            <SvgIcon inheritViewBox color="inherit">
                              <IcEye />
                            </SvgIcon>
                          )}
                        </IconButton>
                      </Tooltip>
                    ),
                  }
                : {}),
            })}
        {...(props.startAdornment
          ? {
              startAdornment: <>{props.startAdornment}</>,
            }
          : undefined)}
        {...(props.endAdornment
          ? {
              endAdornment: <>{props.endAdornment}</>,
            }
          : undefined)}
      />
      {Boolean(props.errorText) && (
        <FormHelperText error sx={{ fontWeight: 500, fontSize: 14 }}>
          {props.errorText}
        </FormHelperText>
      )}
      {Boolean(props.helperText && !props.errorText) && (
        <FormHelperText sx={{ px: "16px" }}>{props.helperText}</FormHelperText>
      )}
      {props.showStrength && (
        <Stack
          alignItems={"flex-end"}
          sx={{
            opacity:
              props.text!.length > 0 && Boolean(props.errorText) === false
                ? 1
                : 0,
            transition: "all .3s",
          }}
        >
          <Stack direction="row" sx={{ width: "100%" }} spacing={"4px"}>
            <Box
              flex={1}
              sx={{
                height: "4px",
                borderRadius: "100px",
                background: passwordStrength >= 1 ? "#2563EB40" : "#DDD",
              }}
            />
            <Box
              flex={1}
              sx={{
                height: "4px",
                borderRadius: "100px",
                background: passwordStrength >= 2 ? "#2563EB80" : "#DDD",
              }}
            />
            <Box
              flex={1}
              sx={{
                height: "4px",
                borderRadius: "100px",
                background: passwordStrength >= 3 ? "#2563EBBF" : "#DDD",
              }}
            />
            <Box
              flex={1}
              sx={{
                height: "4px",
                borderRadius: "100px",
                background: passwordStrength >= 4 ? "#2563EB" : "#DDD",
              }}
            />
          </Stack>
          <Typography
            color={
              passwordStrength <= 1
                ? theme.palette.error.main
                : passwordStrength === 2
                ? theme.palette.warning.main
                : passwordStrength === 3
                ? theme.palette.success.main
                : theme.palette.primary.main
            }
            sx={{
              fontSize: 14,
              fontWeight: 500,
              lineHeight: "24px",
            }}
          >
            {passwordStrength === 0 && "Password must be at least 8 characters"}
            {passwordStrength === 1 && "Weak password"}
            {passwordStrength === 2 && "Medium password"}
            {passwordStrength === 3 && "Good password"}
            {passwordStrength === 4 && "Strong password!"}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
