const VERSION = "v1.0.3";
const debug = {
  version: VERSION,
  debug: true,
  api: "http://localhost:3001",
  rtm: "ws://localhost:3001",
  firebase: {
    apiKey: "AIzaSyAcLzI-nd8B-yQL913oapvK2QG5e0OLXHM",
    authDomain: "quantify-fx.firebaseapp.com",
    projectId: "quantify-fx",
    storageBucket: "quantify-fx.appspot.com",
    messagingSenderId: "1054277345331",
    appId: "1:1054277345331:web:0362deb6527b66494a8346",
  },
  loadingAnimation:
    "https://lottie.host/20dc5b27-2525-4485-a6b6-829fb93dc084/IKsoLolRNw.json",
};

const production = {
  version: VERSION,
  debug: false,
  api: "https://api.quantifyfx.com",
  rtm: "wss://api.quantifyfx.com",
  firebase: {
    apiKey: "AIzaSyAcLzI-nd8B-yQL913oapvK2QG5e0OLXHM",
    authDomain: "quantify-fx.firebaseapp.com",
    projectId: "quantify-fx",
    storageBucket: "quantify-fx.appspot.com",
    messagingSenderId: "1054277345331",
    appId: "1:1054277345331:web:0362deb6527b66494a8346",
  },
  loadingAnimation:
    "https://lottie.host/20dc5b27-2525-4485-a6b6-829fb93dc084/IKsoLolRNw.json",
};

export const AppConfig =
  process.env.REACT_APP_DEBUG === "true" ? debug : production;
