import {
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  Theme,
} from "@mui/material";

export type ZSelectOption = {
  value: string | number;
  label: string;
  heading?: string;
};

export default function ZSelect(props: {
  options: ZSelectOption[];
  value: string;
  onChange?: (selectedOption: ZSelectOption) => void;
  startAdornment?: any;
  sx?: SxProps<Theme>;
  onAdd?: () => void;
  errorText?: string;
}) {
  const selected = props.value
    ? props.options.find((o) => o.value === props.value)
    : undefined;
  return (
    <FormControl sx={props.sx}>
      {selected?.heading && (
        <InputLabel
          sx={{
            pl: "50px",
          }}
          variant="filled"
        >
          {selected?.heading ? selected.heading : undefined}
        </InputLabel>
      )}
      <Select
        error={Boolean(props.errorText)}
        value={props.value}
        variant="filled"
        sx={{
          pl: "8px",
          "& .MuiInputBase-inputAdornedStart": {
            pl: "8px",
          },
        }}
        hiddenLabel={selected?.heading ? false : true}
        label={selected?.heading ? selected.heading : undefined}
        size="small"
        onChange={(e) =>
          props.onChange?.(
            props.options.find((o) => o.value === e.target.value)!
          )
        }
        startAdornment={props.startAdornment}
        disableUnderline
        placeholder="Select an option.."
      >
        {props.options.map((o) => (
          <MenuItem value={o.value}>{o.label}</MenuItem>
        ))}
        {props.onAdd && (
          <>
            <Divider />
            <MenuItem onClick={props.onAdd}>Add</MenuItem>
          </>
        )}
      </Select>
      {props.errorText && <FormHelperText>{props.errorText}</FormHelperText>}
    </FormControl>
  );
}
