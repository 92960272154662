import React from "react";

function IcPieChart() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath="url(#clip0_1078_8127)"
      >
        <path d="M14.14 10.593a6.666 6.666 0 11-8.807-8.706M14.667 8A6.667 6.667 0 008 1.333V8h6.667z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1078_8127">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IcPieChart;
