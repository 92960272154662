import _cubes from "./cubes.svg";
import _logo from "./logo.png";
import _dashboard from "./dashboard.png";
import _cards from "./cards.png";
import _safari from "./safari.png";
import _login_slide from "./login_slide.png";
import _password_changed from "./password-changed-icon.png";

export const IMG_PASSWORD_CHANGED = _password_changed;
export const LOGIN_SLIDE = _login_slide;
export const IMG_CUBES = _cubes;

export const IMG_LOGO = _logo;

export const IMG_SAFARI = _safari;
export const IMG_DASHBOARD = _dashboard;
export const IMG_CARDS = _cards;
