import {
  Avatar,
  Badge,
  Button,
  Divider,
  Popover,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import ImgQuantifyFx from "../assets/images/quantifyfx";
import { useRecoilState } from "recoil";
import { aUser } from "../core/state";
import IcChevronUpDown from "../assets/icons/chevron-up-down";
import ZInput from "./ZInput";
import IcSearch from "../assets/icons/search";
import IcHome from "../assets/icons/home";
import IcUserCircle from "../assets/icons/user-circle";
import IcPieChart from "../assets/icons/pie-chart";
import IcNewspaper from "../assets/icons/newspaper";
import IcStickyNote from "../assets/icons/sticky-note";
import IcTime from "../assets/icons/time";
import { useRef, useState } from "react";
import IcHelp from "../assets/icons/help";
import IcInbox from "../assets/icons/inbox";
import IcSettings from "../assets/icons/settings";
import IcBacknote from "../assets/icons/backnote";
import IC_LIGHT_THEME from "../assets/icons/light-theme";
import IC_DARK_THEME from "../assets/icons/dark-theme";
import IC_SYSTME_THEME from "../assets/icons/system-theme";
import LinkButton from "./LinkButton";
import IcGift from "../assets/icons/gift";
import { useNavigate } from "react-router";
import { FbAuth } from "../core/firebase";

export function MenuButton(props: {
  selected?: string;
  text?: string;
  icon?: any;
  id: string;
  onClick?: (id: string) => void;
}) {
  return (
    <Button
      onClick={() => props.onClick?.(props.id)}
      startIcon={
        props.icon ? (
          <props.icon
            stroke={props.selected === props.id ? "#1F2937" : "#5F6B83"}
          />
        ) : undefined
      }
      sx={{
        background: "transparent",
        borderRadius: "8px",
        border: "1px solid transparent",
        color: "#5F6B83",
        fontSize: "14px",
        fontWeight: 500,
        justifyContent: "start",
        height: "36px",
        ...(props.selected === props.id
          ? {
              color: "#1F2937",
              background: "#FFF",
              border: "1px solid rgba(0, 0, 0, 0.06)",
            }
          : {}),
      }}
    >
      {props.text}
    </Button>
  );
}

export function UserPopup() {
  const [profile] = useRecoilState(aUser);
  return (
    <Stack
      sx={{
        borderRadius: "12px",
        border: "1px solid #E5E7EB",
        background: "#FFF",
        px: "8px",
        minWidth: "234px",
      }}
      spacing={"8px"}
    >
      <Stack spacing={"8px"} sx={{ p: "16px" }}>
        <Stack>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 700,
              color: "#1F2937",
            }}
          >
            {FbAuth.currentUser?.displayName}
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 400,
              color: "#6B7280",
            }}
          >
            {FbAuth.currentUser?.email}
          </Typography>
        </Stack>
        <Stack spacing={"10px"}>
          <Button
            sx={{
              background: "#000000",
              color: "#fff",
              ":hover": {
                background: "#D1D5DB",
                color: "#000",
              },
            }}
            startIcon={<IcGift />}
          >
            Refer Friend
          </Button>
          <Button
            sx={{
              background: "#D1D5DB",
              color: "#000",
              ":hover": {
                background: "#000000",
                color: "#fff",
              },
            }}
          >
            Give Feedback
          </Button>
        </Stack>
      </Stack>

      <Divider />
      {/* Theme Buttons  */}
      <Stack
        sx={{
          flexDirection: "row",
          gap: "16px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* light theme */}
        <Stack>
          <Stack
            sx={{
              width: "52px",
              height: "34px",
              background: "#D9D9D9",
              borderRadius: "14px",
              padding: "6px 3px 3px 14px",
              border: "1px solid #2563EB",
            }}
          >
            <IC_LIGHT_THEME />
          </Stack>
          Light
        </Stack>
        {/* Dark theme */}
        <Stack alignItems={"center"}>
          <Stack
            sx={{
              width: "52px",
              height: "34px",
              background: "#292929",
              borderRadius: "14px",
              padding: "7px 2px 2px 15px",
            }}
          >
            <IC_DARK_THEME />
          </Stack>
          Dark
        </Stack>
        {/* system theme */}
        <Stack alignItems={"center"}>
          <Stack
            sx={{
              width: "52px",
              height: "34px",
            }}
          >
            <IC_SYSTME_THEME />
          </Stack>
          System
        </Stack>
      </Stack>
      <Divider />
      {/* profile and logout */}
      <Stack spacing={"16px"} sx={{ px: "12px" }}>
        <Typography
          sx={{
            color: "#1F2937",
            fontSize: 14,
            fontWeight: 700,
            cursor: "pointer",
          }}
        >
          Profile
        </Typography>
        <Typography
          sx={{
            color: "#1F2937",
            fontSize: 14,
            fontWeight: 700,
            cursor: "pointer",
          }}
        >
          Contact Support
        </Typography>
        <Typography
          onClick={() => FbAuth.signOut()}
          sx={{
            color: "#1F2937",
            fontSize: 14,
            fontWeight: 700,
            cursor: "pointer",
          }}
        >
          Logout
        </Typography>
      </Stack>
      <Divider />
      <Stack direction={"row"} spacing={"16px"} sx={{ p: "16px" }}>
        <Typography
          sx={{
            fontSize: 10,
            fontWeight: 600,
            color: "#6B7280",
            cursor: "pointer",
          }}
        >
          Privacy
        </Typography>
        <Typography
          sx={{
            fontSize: 10,
            fontWeight: 600,
            color: "#6B7280",
            cursor: "pointer",
          }}
        >
          Terms
        </Typography>
      </Stack>
    </Stack>
  );
}

export default function SidebarUser() {
  const [profile, setProfile] = useRecoilState(aUser);
  const [selectedItem, setSelectedItem] = useState("dashboard");
  const accountsSwitcherRef = useRef(null);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const navigate = useNavigate();

  function onMenuItemChange(id: string) {
    setSelectedItem(id);

    navigate(id);
  }

  return (
    <Stack sx={{ width: "260px" }}>
      <Stack
        sx={{
          width: "260px",
          p: "20px",
          background: "#FFFFFF",
          zIndex: 10,
          position: "fixed",
          height: "100vh",
        }}
        spacing={"20px"}
      >
        <Popover
          open={showAccountMenu}
          anchorEl={accountsSwitcherRef.current}
          onClose={() => setShowAccountMenu(false)}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        >
          <UserPopup />
        </Popover>

        {/* Logo and User Icon  */}
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <ImgQuantifyFx />
          <Stack
            onClick={() => setShowAccountMenu(!showAccountMenu)}
            ref={accountsSwitcherRef}
            direction={"row"}
            alignItems={"center"}
            spacing={"4px"}
            sx={{
              p: "4px",
              borderRadius: "4px",
              cursor: "pointer",
              ":hover": {
                background: "#DDD",
                p: "4px",
                borderRadius: "4px",
              },
            }}
          >
            <Avatar
              sx={{ height: "26px", width: "26px" }}
              src={profile?.image}
              alt={profile?.firstName}
            >
              {profile?.firstName[0]}
            </Avatar>
            {/* Updown Arrows */}
            <IcChevronUpDown />
          </Stack>
        </Stack>

        {/* Search Icon  and Text*/}
        <Stack>
          <ZInput startIcon={<IcSearch />} placeholder="Search..." />
        </Stack>

        {/* Menu Buttons  */}
        <Stack flex={1} spacing={"6px"}>
          <MenuButton
            id="/"
            text="Dashboard"
            onClick={onMenuItemChange}
            selected={selectedItem}
            icon={IcHome}
          />
          <MenuButton
            id="/accounts"
            text="Accounts"
            onClick={onMenuItemChange}
            selected={selectedItem}
            icon={IcUserCircle}
          />
          <MenuButton
            id="/analysis-hub"
            text="Analysis Hub"
            selected={selectedItem}
            onClick={onMenuItemChange}
            icon={IcPieChart}
          />
          <MenuButton
            id="/trading-journal"
            text="Trading Journal"
            selected={selectedItem}
            onClick={onMenuItemChange}
            icon={IcNewspaper}
          />
          <MenuButton
            id="/trading-history"
            text="Trading History"
            selected={selectedItem}
            onClick={onMenuItemChange}
            icon={IcTime}
          />
          <MenuButton
            id="/leaderboard"
            text="Leaderboard"
            selected={selectedItem}
            onClick={onMenuItemChange}
            icon={IcStickyNote}
          />
        </Stack>

        <Stack>
          <MenuButton
            id="/help"
            text="Help"
            onClick={onMenuItemChange}
            selected={selectedItem}
            icon={IcHelp}
          />
          <MenuButton
            id="/inbox"
            text="Inbox"
            onClick={onMenuItemChange}
            selected={selectedItem}
            icon={IcInbox}
          />
          <MenuButton
            id="/settings"
            text="Settings"
            onClick={onMenuItemChange}
            selected={selectedItem}
            icon={IcSettings}
          />
          <MenuButton
            id="/free"
            text="1 Month Free"
            onClick={onMenuItemChange}
            selected={selectedItem}
            icon={IcBacknote}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
