import { Checkbox, FormControlLabel, styled } from "@mui/material";
import { IC_CHECKBOX_CHECKED } from "../assets/icons";

const Unchecked = styled("span")(({ theme }) => ({
  borderRadius: 4,
  border: "1px solid #E5E7EB",
  width: 16,
  height: 16,
  backgroundColor: "#FFF",

  "input:hover ~ &": {
    backgroundColor: "#ebf1f5",
  },
  "input:disabled ~ &": {
    background: "rgba(206,217,224,.5)",
  },
}));

const Checked = styled("span")(({ theme }) => ({
  borderRadius: 4,
  border: "1px solid #2563EB",
  width: 16,
  height: 16,
  backgroundColor: "#2563EB",
  backgroundImage: `url(${IC_CHECKBOX_CHECKED})`,

  "input:hover ~ &": {
    backgroundColor: "#2563EBAA",
  },
  "input:disabled ~ &": {
    background: "rgba(206,217,224,.5)",
  },
}));

export default function ZCheckbox(props: {
  label: string;
  value?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
}) {
  return (
    <FormControlLabel
      disabled={props.disabled}
      control={
        <Checkbox
          checked={props.value}
          onChange={(e, v) => props.onChange?.(v)}
          icon={<Unchecked />}
          checkedIcon={<Checked />}
        />
      }
      label={props.label}
    ></FormControlLabel>
  );
}
