import React from "react";

function IcUserCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath="url(#clip0_1078_8117)"
      >
        <path d="M8 14.667A6.667 6.667 0 108 1.333a6.667 6.667 0 000 13.334z"></path>
        <path d="M8 8.667a2 2 0 100-4 2 2 0 000 4zM4.667 13.775v-1.108A1.333 1.333 0 016 11.333h4a1.333 1.333 0 011.333 1.334v1.108"></path>
      </g>
      <defs>
        <clipPath id="clip0_1078_8117">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IcUserCircle;
