import { GetRtmClient } from ".";
import { TradingJournalFilter } from "../../dialogs/TradingJournalFilter";
import { TSTrade } from "../../types/TSTrade";

export async function RtmConnectAccount(options: {
  server: string;
  password: string;
  login: string;
  platform: 4 | 5;
}) {
  const _rtm = await GetRtmClient();
  const prof = await _rtm.CallWait<Boolean>("RtmConnectAccount", options);
  return prof;
}

export async function RtmGetBrokerServers(platform: "4" | "5", broker: string) {
  const _rtm = await GetRtmClient();
  const _data = await _rtm.CallWait<any[]>("RtmGetBrokerServers", {
    platform,
    broker,
  });
  return _data;
}
export async function RtmGetBrokers(platform: "4" | "5") {
  const _rtm = await GetRtmClient();
  const _data = await _rtm.CallWait<any[]>("RtmGetBrokers", platform);
  return _data;
}

export async function RtmGetTradingJournalStats(
  period: "30-days" | "3-months" | "6-months" | "1-year",
  accountId?: string
) {
  const _rtm = await GetRtmClient();
  const _data = await _rtm.CallWait<{
    chart: {
      growth: { x: number; y: number }[];
      equity: { x: number; y: number }[];
      previous: number;
    };
    trades: {
      wins: number;
      losses: number;
      count: number;
      days: number;
      percentByTrades: number;
      percentByDays: number;
      averageWin: number;
      averageLoss: number;
      winningDays: number;
      losingDays: number;
    };
    netPnl: number;
    profitFactor: number;
    totalPnl: number;
  }>("RtmGetTradingJournalStats", {
    period,
    now: new Date().toISOString(),
    accountId,
  });
  return _data;
}

export async function RtmGetTrades(
  from: number,
  to: number,
  filter?: TradingJournalFilter,
  account?: string
) {
  const _rtm = await GetRtmClient();
  const _data = await _rtm.CallWait<TSTrade[]>("RtmGetTrades", {
    from,
    to,
    filter,
    account,
  });
  return _data;
}

export async function RtmGetTrade(id: number) {
  const _rtm = await GetRtmClient();
  const _data = await _rtm.CallWait<TSTrade>("RtmGetTrade", id);
  return _data;
}
export async function RtmUpdateTrade(
  id: number,
  update: { plan?: any; tradingViewLink?: any; activity?: any }
) {
  const _rtm = await GetRtmClient();
  const _data = await _rtm.CallWait<boolean>("RtmUpdateTrade", {
    position: id,
    plan: update.plan,
    tradingViewLink: update.tradingViewLink,
    activity: update.activity,
  });
  return _data;
}

export async function RtmGetLinkPreview(link: string) {
  const _rtm = await GetRtmClient();
  const _data = await _rtm.CallWait<
    false | { title: string; description: string; thumbnail: string }
  >("RtmGetLinkPreview", link);
  return _data;
}

export async function RtmGetDashboardStats(
  period: "30-days" | "3-months" | "6-months" | "1-year",
  accountId?: string
) {
  const _rtm = await GetRtmClient();
  const _data = await _rtm.CallWait<{
    charts: {
      balance: {
        x: string;
        y: number;
      }[];
      equity: {
        x: string;
        y: number;
      }[];
      score: {
        x: string;
        y: number;
      }[];
      pnl: {
        x: string;
        y: number;
      }[];
      previousScore: number;
    };
    tradeDays: {
      total: number;
      winning: number;
      losing: number;
    };
    topTrades: {
      id: string;
      profit: number;
    }[];
    todaysPnl: number;
    yesterdaysPnl: number;
  }>("RtmGetDashboardStats", {
    period,
    now: new Date().toISOString(),
    accountId,
  });
  return _data;
}
