import { useEffect, useState } from "react";
import { ConnectionState, RtmClientEvent, RtmSubscribe } from ".";

export default function useRtmUpdate(
  update: RtmClientEvent,
  ready?: boolean,
  filter?: (update: any) => boolean
) {
  const [lastMessage, setLastMessage] = useState<any>();

  useEffect(() => {
    if (!ready || ConnectionState !== "CONNECTED") return;
    const _unsub = RtmSubscribe(update, (data: any) => {
      if (data) {
        if (filter && !filter(data)) return;
        setLastMessage(data);
      }
    });
    return () => {
      _unsub?.();
    };
  }, [ready, filter, update]);

  return [lastMessage];
}
