import React from "react";

function IC_SYSTME_THEME() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="34"
      viewBox="0 0 52 34"
      fill="none"
    >
      <path
        d="M0 10C0 4.47715 4.47715 0 10 0H42C47.5228 0 52 4.47715 52 10V24C52 29.5228 47.5228 34 42 34H10C4.47715 34 0 29.5228 0 24V10Z"
        fill="#D9D9D9"
      />
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H27V34H8C3.58172 34 0 30.4183 0 26V8Z"
        fill="#292929"
      />
      <path
        d="M20.5 8.3V26.7C20.5 27.1433 20.2643 27.5827 19.8145 27.9276C19.3648 28.2724 18.7237 28.5 18 28.5H9C8.27628 28.5 7.63525 28.2724 7.18547 27.9276C6.73567 27.5827 6.5 27.1433 6.5 26.7V8.3C6.5 7.85672 6.73567 7.41727 7.18547 7.07243C7.63525 6.7276 8.27628 6.5 9 6.5H18C18.7237 6.5 19.3648 6.7276 19.8145 7.07243C20.2643 7.41727 20.5 7.85672 20.5 8.3Z"
        fill="#353535"
        stroke="#121212"
      />
      <path
        d="M47.5 8.3V26.7C47.5 27.1433 47.2643 27.5827 46.8145 27.9276C46.3648 28.2724 45.7237 28.5 45 28.5H36C35.2763 28.5 34.6352 28.2724 34.1855 27.9276C33.7357 27.5827 33.5 27.1433 33.5 26.7V8.3C33.5 7.85672 33.7357 7.41727 34.1855 7.07243C34.6352 6.7276 35.2763 6.5 36 6.5H45C45.7237 6.5 46.3648 6.7276 46.8145 7.07243C47.2643 7.41727 47.5 7.85672 47.5 8.3Z"
        fill="#E3E3E3"
        stroke="white"
      />
    </svg>
  );
}

export default IC_SYSTME_THEME;
