import { atom } from "recoil";
import { UserAccount } from "../types/UserAccount";

export const aUser = atom<UserAccount>({
  default: undefined,
  key: "quantifyfx_user",
});

export const aSelectedAccount = atom<string>({
  default: undefined,
  key: "quantifyfx_account",
});
