import * as React from "react";

const IcFilter: React.FC<React.SVGProps<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="#fff"
      fillOpacity="0.01"
      d="M0 0h20v20H0z"
      style={{ mixBlendMode: "multiply" }}
    ></path>
    <path
      fill="#020617"
      stroke="#1F2937"
      d="M8 11.506V11.3l-.146-.146L3.222 6.52A.75.75 0 0 1 3 5.992V3.75A.75.75 0 0 1 3.75 3h12.5a.75.75 0 0 1 .75.75v2.243a.75.75 0 0 1-.221.527l-.001.001-4.632 4.632-.146.146v4.951a.75.75 0 0 1-.75.75h-2.5a.75.75 0 0 1-.75-.75zM3.75 3.25h-.5v2.951l.146.146 4.854 4.854v5.549h3.5V11.2l4.854-4.853.146-.146V3.25h-13Z"
    ></path>
  </svg>
);

export default IcFilter;
