import { Paper, Stack, Typography, useTheme } from "@mui/material";
import { PieChart } from "@mui/x-charts";

export default function TradingJournalProgressCard(props: {
  primaryValue: number;
  primaryLabel: string;
  secondaryValue: number;
  secondaryLabel: string;
  heading: string;
  value: string;
  centerHeading?: string;
  centerText?: string;
}) {
  const theme = useTheme();
  return (
    <Paper elevation={2} sx={{ p: "16px", maxWidth: "288px" }}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack spacing={"8px"}>
          <Typography
            sx={{
              color: theme.palette.grey["400"],
              fontSize: 12,
              fontWeight: 400,
            }}
          >
            {props.heading}
          </Typography>
          <Typography
            sx={{
              color: theme.palette.text.primary,
              fontSize: 20,
              fontWeight: 500,
            }}
          >
            {props.value}
          </Typography>
          <Stack flex={1} direction={"row"} spacing={"8px"}>
            <Stack direction={"row"} alignItems={"center"} spacing={"4px"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <circle
                  cx="8"
                  cy="8"
                  r="4"
                  stroke="#298C6B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 500,
                  color: theme.palette.grey["400"],
                }}
              >
                {props.primaryLabel}
              </Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} spacing={"4px"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <circle
                  cx="8"
                  cy="8"
                  r="4"
                  stroke="#EF4444"
                  stroke-opacity="0.1"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 500,
                  color: theme.palette.grey["400"],
                }}
              >
                {props.secondaryLabel}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          sx={{
            position: "relative",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PieChart
            height={128}
            width={128}
            series={[
              {
                data: [
                  {
                    value: props.primaryValue,
                    color: "#298C6B",
                    label: props.primaryLabel,
                  },
                  {
                    value: props.secondaryValue,
                    color: "#EF44441A",
                    label: props.secondaryLabel,
                  },
                ],
                paddingAngle: 4,
                cornerRadius: 6,
                startAngle: 0,
                endAngle: 365,
                innerRadius: 40,
                highlightScope: { fade: "global", highlight: "item" },
                highlighted: {},
                outerRadius: 60,
                cx: 60,
                cy: 60,
              },
            ]}
            slotProps={{ legend: { hidden: true } }}
          />
          <Stack
            sx={{
              position: "absolute",
              height: "100%",
              width: "100%",
              pointerEvents: "none",
            }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                color: theme.palette.text.primary,
              }}
            >
              {props.centerHeading}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 400,
                color: theme.palette.grey["400"],
              }}
            >
              {props.centerText}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
}
