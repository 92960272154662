import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Radio,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import IcMetatrader from "../assets/icons/IcMetatrader";
import IcChevronRight from "../assets/icons/IcChevronRight";
import { enqueueSnackbar } from "notistack";
import {
  RtmConnectAccount,
  RtmGetBrokers,
  RtmGetBrokerServers,
} from "../core/rtm/user";
import ZInput from "./ZInput";
import IcSearch from "../assets/icons/search";
import ZSelect from "./ZSelect";
import { AnimatePresence } from "framer-motion";
import IcAlertTriangle from "../assets/icons/alert-triangle";
import { motion } from "framer-motion";
import { useNavigate } from "react-router";

export default function AccountOnboarding() {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);

  const [selectedPlatform, setSelectedPlatform] = useState<"mt4" | "mt5">(
    "mt4"
  );

  const [busy, setBusy] = useState(false);
  const [error, setError] = useState("");
  const [errorDescription, setErrorDescription] = useState("");

  const [brokers, setBrokers] = useState<any[]>([]);

  const [shownBrokers, setShownBrokers] = useState<any[]>([]);
  const [selectedBroker, setSelectedBroker] = useState<any>();
  const [servers, setServers] = useState<any[]>([]);
  const [selectedServer, setSelectedServer] = useState("");

  const [searchText, setSearchText] = useState("");

  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");

  const loadBrokers = useCallback(async () => {
    try {
      const _servers = await RtmGetBrokers(
        selectedPlatform === "mt4" ? "4" : "5"
      );
      setBrokers(_servers);
      console.log(_servers);
      setShownBrokers(_servers);
    } catch (err: any) {
      enqueueSnackbar("Error loading servers. Please try again.", {
        variant: "error",
      });
      console.error("Error loading servers. ", err);
    }
  }, [selectedPlatform]);

  const loadServers = useCallback(async () => {
    try {
      if (!selectedBroker) return;
      const _servers = await RtmGetBrokerServers(
        selectedPlatform === "mt4" ? "4" : "5",
        selectedBroker?.id
      );
      setServers(_servers);
    } catch (err: any) {
      enqueueSnackbar("Error loading servers. Please try again.", {
        variant: "error",
      });
      console.error("Error loading servers. ", err);
    }
  }, [selectedBroker]);

  async function connect() {
    try {
      setError("");
      if (!selectedServer) {
        setError("server");
        return;
      }
      if (!account) {
        setError("account");
        return;
      }
      if (!password) {
        setError("password");
        return;
      }
      setBusy(true);
      await RtmConnectAccount({
        login: account,
        password: password,
        platform: selectedPlatform === "mt4" ? 4 : 5,
        server: selectedServer,
      });
      enqueueSnackbar(
        "Your account has been connected. It might take a while to synchronize your data.",
        { variant: "success" }
      );
      setStep(step + 1);
    } catch (err: any) {
      console.error("Error connecting the account. ", err);
      enqueueSnackbar("Error connecting your account. ", { variant: "error" });
    }
    setBusy(false);
  }

  useEffect(() => {
    loadBrokers();
  }, [loadBrokers]);
  useEffect(() => {
    loadServers();
  }, [loadServers]);

  useEffect(() => {
    if (searchText) {
      const _filtered = brokers.filter((s) => s.name?.includes(searchText));
      setShownBrokers(_filtered);
    } else {
      setShownBrokers([...brokers]);
    }
  }, [searchText, brokers]);

  return (
    <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
      {step === 0 && (
        <Stack spacing={"32px"}>
          <Stack spacing={"12px"}>
            <Typography
              sx={{
                fontSize: 24,
                fontWeight: 600,
                color: "#000",
              }}
            >
              Choose platform
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                color: "#110000CC",
              }}
            >
              Choose the platform of your trading account.
            </Typography>
          </Stack>
          <Stack spacing={"12px"}>
            <PlatformOption
              onClick={() => setSelectedPlatform("mt4")}
              selected={selectedPlatform === "mt4"}
              label="MetaTrader 4"
            />
            <PlatformOption
              onClick={() => setSelectedPlatform("mt5")}
              selected={selectedPlatform === "mt5"}
              label="MetaTrader 5"
            />
          </Stack>
          <Button
            onClick={() => setStep(step + 1)}
            variant="contained"
            color="primary"
          >
            Continue
          </Button>
        </Stack>
      )}

      {step === 1 && (
        <Stack spacing={"32px"}>
          <Stack spacing={"12px"}>
            <Typography
              sx={{
                fontSize: 24,
                fontWeight: 600,
                color: "#000",
              }}
            >
              Choose platform
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                color: "#110000CC",
              }}
            >
              Choose the platform of your trading account.
            </Typography>
          </Stack>
          {/* Search Input and List */}
          <Stack
            spacing={"24px"}
            sx={{ minWidth: "520px", overflow: "hidden" }}
          >
            <Stack>
              <ZInput
                placeholder="Search..."
                startIcon={<IcSearch />}
                text={searchText}
                onUpdate={(t) => setSearchText(t)}
              />
            </Stack>
            {/* List  */}
            <Stack sx={{ overflow: "auto", height: "475px" }}>
              {shownBrokers.map((s) => (
                <Stack
                  sx={{
                    cursor: "pointer",
                    py: "12px",
                    borderBottom: "1px solid #E5E7EB",
                  }}
                  onClick={() => setSelectedBroker(s)}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 600, color: "#1F2937" }}
                  >
                    {s.name}
                  </Typography>
                  <Radio checked={selectedBroker?.id === s.id} />
                </Stack>
              ))}
            </Stack>
          </Stack>
          <Button
            onClick={() => setStep(step + 1)}
            variant="contained"
            color="primary"
          >
            Continue
          </Button>
        </Stack>
      )}

      {step === 2 && (
        <Stack spacing={"32px"} sx={{ minWidth: "520px" }}>
          <Stack spacing={"12px"}>
            <Typography
              sx={{
                fontSize: 24,
                fontWeight: 600,
                color: "#000",
              }}
            >
              Log in to your account
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                color: "#110000CC",
              }}
            >
              We use Bank-level, 256-bit encryption to keep your data safe.
            </Typography>
          </Stack>
          <AnimatePresence>
            {error === "login" && (
              <motion.div
                key={"loginError"}
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
              >
                <Alert
                  variant="filled"
                  color="error"
                  icon={<IcAlertTriangle />}
                >
                  <AlertTitle>Error logging in to your account.</AlertTitle>
                  <Typography>{errorDescription}</Typography>
                </Alert>
              </motion.div>
            )}
          </AnimatePresence>
          <Stack spacing={"24px"}>
            <Stack spacing={"8px"}>
              <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                Server
              </Typography>
              <ZSelect
                errorText={error === "server" ? "Please select a server." : ""}
                options={servers.map((s) => {
                  return { label: s.name, value: s.id };
                })}
                onChange={(o) => setSelectedServer(o.value.toString())}
                value={selectedServer}
              />
            </Stack>
            <ZInput
              placeholder="Enter your account number"
              label="Account number"
              errorText={
                error === "account" ? "Please enter your account number." : ""
              }
              text={account}
              onUpdate={(t) => setAccount(t)}
            />

            <ZInput
              placeholder="Enter your account password"
              label="Password"
              text={password}
              errorText={
                error === "password"
                  ? "Please enter your account password."
                  : ""
              }
              password
              onUpdate={(t) => setPassword(t)}
            />
          </Stack>

          <Button
            disabled={busy}
            onClick={connect}
            variant="contained"
            color="primary"
          >
            {busy && (
              <>
                <CircularProgress
                  variant="indeterminate"
                  color="secondary"
                  size={"20px"}
                />
              </>
            )}
            {!busy && "Continue"}
          </Button>
        </Stack>
      )}

      {step === 3 && (
        <Stack spacing={"32px"} sx={{ minWidth: "520px" }}>
          <Stack spacing={"12px"}>
            <Typography
              sx={{
                fontSize: 24,
                fontWeight: 600,
                color: "#000",
              }}
            >
              Your account has been connected!
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                color: "#110000CC",
              }}
            >
              You have successfully connected your account. Please note that it
              may take a while to synchronize your account statistics.
            </Typography>
          </Stack>
          <Button
            onClick={() => navigate("/")}
            variant="contained"
            color="primary"
          >
            Back to Dashboard
          </Button>
        </Stack>
      )}
    </Stack>
  );
}

function PlatformOption(props: {
  selected?: boolean;
  label?: string;
  onClick?: () => void;
}) {
  return (
    <Stack
      onClick={props.onClick}
      direction={"row"}
      sx={{
        height: "76px",
        borderRadius: "8px",
        border: props.selected ? "1px solid #1C1D20" : "1px solid #E5E7EB",
        overflow: "hidden",
        background: props.selected ? "#000" : "",
        cursor: "pointer",
        minWidth: "474px",
        transition: "all .3s",
      }}
    >
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          height: "100%",
          width: "46px",
          background: props.selected ? "#000" : "#FAFAFA",
          transition: "all .3s",
        }}
      >
        <SvgIcon
          htmlColor={props.selected ? "#fff" : "#6B7280"}
          sx={{ height: "22ppx", width: "22px", transition: "all .3s" }}
        >
          <IcMetatrader />
        </SvgIcon>
      </Stack>
      <Stack
        flex={1}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          p: "16px",
          borderLeft: props.selected ? "1px solid #FFF" : "1px solid #E5E7EB",
          borderRadius: "8px",
          background: "#FFF",
          transition: "all .3s",
        }}
      >
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: "#1F2937",
          }}
        >
          {props.label}
        </Typography>
        <IcChevronRight />
      </Stack>
    </Stack>
  );
}
