import React from "react";

function IcTrendDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      style={{ transform: "rotate(180deg)" }}
    >
      <path
        fill="#fff"
        fillOpacity="0.01"
        style={{ mixBlendMode: "multiply" }}
        d="M0 0H16V16H0z"
      ></path>
      <path fill="#EF4444" d="M4 10l4-5 4 5H4z"></path>
    </svg>
  );
}

export default IcTrendDown;
