import React from "react";

function IcAlertTriangle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#991B1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.487 12L9.153 2.667a1.333 1.333 0 00-2.32 0L1.5 12a1.333 1.333 0 001.167 2h10.666a1.333 1.333 0 001.154-2zM8 6v2.667M8 11.333h.007"
      ></path>
    </svg>
  );
}

export default IcAlertTriangle;
