import React from "react";

function IcHelp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath="url(#clip0_1078_8164)"
      >
        <path d="M8 14.667A6.667 6.667 0 108 1.333a6.667 6.667 0 000 13.334z"></path>
        <path d="M6.06 6a2 2 0 013.887.667c0 1.333-2 2-2 2M8 11.333h.007"></path>
      </g>
      <defs>
        <clipPath id="clip0_1078_8164">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IcHelp;
