import {
  Alert,
  AlertTitle,
  Button,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { enqueueSnackbar } from "notistack";
import { FbAuth } from "../../core/firebase";
import ZInput from "../../components/ZInput";
import { IMG_CUBES, IMG_LOGO } from "../../assets/images";
import ZCheckbox from "../../components/ZCheckbox";
import IcAlertTriangle from "../../assets/icons/alert-triangle";
import { AnimatePresence, motion } from "framer-motion";
import IcArrowLeft from "../../assets/icons/arrow-left";
import ImgQuantifyFx from "../../assets/images/quantifyfx";

export default function PageForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [sent, setSent] = useState(false);

  const navigate = useNavigate();

  async function submit() {
    try {
      if (!email) {
        setError("email");
        return;
      }
      setBusy(true);
      setLoginError(false);
      await sendPasswordResetEmail(FbAuth, email);
      enqueueSnackbar("Password reset email has been sent. ", {
        variant: "success",
      });
      setSent(true);
    } catch (err: any) {
      if (err.code) {
        if (err.code === "auth/internal-error") {
          enqueueSnackbar("Too many attempts. Please try again later. ", {
            variant: "error",
          });
        } else {
          setLoginError(true);
        }
      } else {
        setLoginError(true);
      }
    }
    setBusy(false);
    return false;
  }

  return (
    <Stack
      sx={{
        minHeight: "100vh",
        transition: "all .2s",
        opacity: 1,
        minWidth: "100vw",
        backgroundPosition: "center",
      }}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Stack sx={{ position: "fixed", top: 32, left: 32 }}>
        <ImgQuantifyFx />
      </Stack>
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ maxWidth: "1280px", width: "100%" }}
        direction={"row"}
      >
        {/* The signup form */}
        <Stack
          spacing="40px"
          sx={{
            p: "20px",
            borderRadius: "20px",
            width: "440px",
          }}
        >
          {/* Heading and Text  */}
          <Stack alignItems={"center"} spacing={"6px"}>
            <Typography
              variant="h1"
              sx={{ fontSize: "24px", fontWeight: "600", lineHeight: "44px" }}
            >
              {sent ? "Check your email" : "Forgot password?"}
            </Typography>
            <Stack spacing="16px" alignItems={"center"}>
              <Typography variant="subtitle1" textAlign={"center"}>
                {sent && (
                  <span>
                    Check your email, a password reset link has been sent to
                    <b>{" " + email}</b>.
                  </span>
                )}
                {!sent && "Enter your email to reset your password."}
              </Typography>
              {sent && (
                <Typography fontSize={14} color={"#6B7280"}>
                  Didn't receive the email?{" "}
                  <Link sx={{ cursor: "pointer" }} onClick={() => {}}>
                    Click to resend it
                  </Link>
                </Typography>
              )}
            </Stack>
          </Stack>

          <Stack spacing={"16px"}>
            <Stack spacing="20px">
              <AnimatePresence>
                {loginError && (
                  <motion.div
                    key={"loginError"}
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                  >
                    <Alert
                      variant="filled"
                      color="error"
                      icon={<IcAlertTriangle />}
                    >
                      <AlertTitle>
                        We did not recognize the credentials.
                      </AlertTitle>
                      <Typography>
                        Please check your email and try again.
                      </Typography>
                    </Alert>
                  </motion.div>
                )}
              </AnimatePresence>
              {!sent && (
                <ZInput
                  disabled={busy}
                  label="Email address"
                  placeholder="johndoe@example.com"
                  text={email}
                  onUpdate={(t) => setEmail(t)}
                  errorText={
                    error === "email"
                      ? "Please enter a valid email address."
                      : ""
                  }
                />
              )}
            </Stack>
          </Stack>
          <Stack spacing={"16px"}>
            {!sent && (
              <Button
                color="secondary"
                variant="outlined"
                disabled={busy}
                onClick={submit}
              >
                Reset Password
              </Button>
            )}
            <Button
              color="secondary"
              variant="text"
              disabled={busy}
              startIcon={<IcArrowLeft />}
              onClick={() => navigate("/login")}
            >
              Back to log in
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
