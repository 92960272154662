import React from "react";

function IcBacknote() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.333 4H2.667c-.737 0-1.334.597-1.334 1.333v5.334c0 .736.597 1.333 1.334 1.333h10.666c.737 0 1.334-.597 1.334-1.333V5.333c0-.736-.597-1.333-1.334-1.333z"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 9.333a1.333 1.333 0 100-2.666 1.333 1.333 0 000 2.666zM4 8h.007M12 8h.007"
      ></path>
    </svg>
  );
}

export default IcBacknote;
