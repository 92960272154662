import {
  Alert,
  AlertTitle,
  Button,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { signInWithEmailAndPassword } from "firebase/auth";
import { enqueueSnackbar } from "notistack";
import { FbAuth } from "../../core/firebase";
import ZInput from "../../components/ZInput";
import { LOGIN_SLIDE } from "../../assets/images";
import ImgQuantifyFx from "../../assets/images/quantifyfx";
import { AnimatePresence, motion } from "framer-motion";
import ZCheckbox from "../../components/ZCheckbox";
import IcAlertTriangle from "../../assets/icons/alert-triangle";

export default function PageSignin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const navigate = useNavigate();

  async function login() {
    try {
      if (!email) {
        setError("email");
        return;
      }
      if (!password) {
        setError("password");
        return;
      }
      setBusy(true);
      setLoginError(false);

      // Create account on firebase.
      const acc = await signInWithEmailAndPassword(FbAuth, email, password);
      if (acc.user) {
        navigate("/dashboard");
        setBusy(false);
        return true;
      }
    } catch (err: any) {
      if (err.code) {
        if (err.code === "auth/internal-error") {
          enqueueSnackbar("Too many attempts. Please try again later. ", {
            variant: "error",
          });
        } else {
          setLoginError(true);
        }
      } else {
        setLoginError(true);
      }
    }
    setBusy(false);
    return false;
  }

  async function load() {
    if (FbAuth.currentUser) {
    } else {
      console.log("Not logged in.");
    }
  }

  useEffect(() => {
    return FbAuth.onAuthStateChanged((state) => {
      load();
    });
  }, []);

  useEffect(() => {
    return FbAuth.onAuthStateChanged((state) => {
      load();
    });
  }, []);

  return (
    <Stack
      sx={{
        minHeight: "100vh",
        transition: "all .2s",
        opacity: 1,
        minWidth: "100vw",
        background: "#FFF",
        overflow: "clip",
        position: "relative",
      }}
      alignItems={"center"}
    >
      <Stack sx={{ position: "fixed", top: 32, left: 32 }}>
        <ImgQuantifyFx />
      </Stack>
      <Stack
        flex={1}
        sx={{
          maxWidth: "1280px",
          width: "100%",
          alignSelf: "start",
          px: "96px",
        }}
        direction={"row"}
      >
        {/* The signup form */}
        <Stack
          justifyContent={"center"}
          spacing="40px"
          sx={{
            height: "100vh",
            p: "96px",
            zIndex: 10,
            position: "relative",
          }}
        >
          <Typography
            variant="h1"
            sx={{ fontSize: "36px", fontWeight: "600", lineHeight: "44px" }}
          >
            Welcome back!
          </Typography>
          <Stack spacing="20px" sx={{ minWidth: "400px" }}>
            <AnimatePresence>
              {loginError && (
                <motion.div
                  key={"loginError"}
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                >
                  <Alert
                    variant="filled"
                    color="error"
                    icon={<IcAlertTriangle />}
                  >
                    <AlertTitle>
                      We did not recognize the credentials.
                    </AlertTitle>
                    <Typography>
                      Please check your email and password and try again.
                    </Typography>
                  </Alert>
                </motion.div>
              )}
            </AnimatePresence>
            <ZInput
              label="Email address"
              placeholder="johndoe@example.com"
              text={email}
              onUpdate={(t) => setEmail(t)}
              errorText={
                error === "email" ? "Please enter a valid email address." : ""
              }
            />
            <ZInput
              label="Password"
              text={password}
              onUpdate={(t) => setPassword(t)}
              password
              placeholder="Enter a strong password"
              onReturn={login}
              errorText={error === "password" ? "Invalid password." : ""}
            />
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <ZCheckbox label="Remember me" />
              <Link href="/forgot-password">Forgot password?</Link>
            </Stack>
          </Stack>

          <Stack spacing={"8px"}>
            <Button variant="contained" disabled={busy} onClick={login}>
              Log in
            </Button>
            <Typography fontSize={12} fontWeight={400}>
              Don't have an account yet? <Link href="/signup">Sign up now</Link>
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {/* Layer 1 */}
      <motion.img
        src={LOGIN_SLIDE}
        style={{
          objectFit: "contain",
          height: "100%",
          top: "40%",
          left: "40%",
          position: "absolute",
          filter: "drop-shadow(0px 12px 32px rgba(0, 0, 0, 0.12)) blur(1px)",
        }}
        initial={{
          opacity: 0,
          transform: "translate(50%, 0)",
        }}
        animate={{
          zIndex: 1,
          transform: "translate(0, 0)",
          opacity: 0.32,
        }}
        transition={{ duration: 1, delay: 1.5 }}
      />

      {/* Layer 2 */}
      <motion.img
        src={LOGIN_SLIDE}
        style={{
          objectFit: "contain",
          height: "100%",
          top: "40%",
          left: "40%",
          position: "absolute",
          filter: "drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.12)) blur(0.4px)",
        }}
        initial={{
          opacity: 0,
          transform: "translate(50%, -60px)",
        }}
        animate={{
          zIndex: 1,
          transform: "translate(0, -60px)",
          opacity: 0.4,
        }}
        transition={{ duration: 1, delay: 1.8 }}
      />

      {/* Layer 3 */}
      <motion.img
        src={LOGIN_SLIDE}
        style={{
          objectFit: "contain",
          height: "100%",
          top: "40%",
          left: "40%",
          position: "absolute",
          filter: "drop-shadow(0px 12px 12px rgba(0, 0, 0, 0.12)) blur(0.2px)",
        }}
        initial={{
          opacity: 0,
          transform: "translate(50%, -120px)",
        }}
        animate={{
          zIndex: 1,
          transform: "translate(0, -120px)",
          opacity: 0.6,
        }}
        transition={{ duration: 1, delay: 2 }}
      />

      {/* Layer 4 */}
      <motion.img
        src={LOGIN_SLIDE}
        style={{
          objectFit: "contain",
          height: "100%",
          top: "40%",
          left: "40%",
          position: "absolute",
          filter: "drop-shadow(0px 12px 6px rgba(0, 0, 0, 0.12)) ",
        }}
        initial={{
          opacity: 0,
          transform: "translate(50%, -180px)",
        }}
        animate={{
          zIndex: 1,
          transform: "translate(0, -180px)",
          opacity: 1,
        }}
        transition={{ duration: 1, delay: 2.1 }}
      />
    </Stack>
  );
}
