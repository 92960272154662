import {
  Alert,
  AlertTitle,
  Button,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  confirmPasswordReset,
  signInWithEmailAndPassword,
  updatePassword,
} from "firebase/auth";
import { enqueueSnackbar } from "notistack";
import { FbAuth } from "../../core/firebase";
import ZInput from "../../components/ZInput";
import { IMG_CUBES, IMG_LOGO, IMG_PASSWORD_CHANGED } from "../../assets/images";
import ZCheckbox from "../../components/ZCheckbox";
import IcAlertTriangle from "../../assets/icons/alert-triangle";
import { AnimatePresence, motion } from "framer-motion";
import IcArrowLeft from "../../assets/icons/arrow-left";
import ImgQuantifyFx from "../../assets/images/quantifyfx";

export default function PageUpdatePassword() {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [strength, setStrength] = useState(0);
  const loc = useLocation();
  const code = loc.state?.code;

  const navigate = useNavigate();

  async function submitNewPassword() {
    try {
      if (strength !== 4) {
        setError("password");
        return;
      }
      setBusy(true);
      setLoginError(false);
      await confirmPasswordReset(FbAuth, code, password);
      // Send verification email.
      enqueueSnackbar("Your password has been updated.", {
        variant: "success",
      });
      // IF continue url is set, we navigatte.
      setIsChanged(true);
    } catch (err: any) {
      if (err.code) {
        if (err.code === "auth/internal-error") {
          enqueueSnackbar("Too many attempts. Please try again later. ", {
            variant: "error",
          });
        } else {
          setLoginError(true);
        }
      } else {
        setLoginError(true);
      }
    }
    setBusy(false);
    return false;
  }

  return (
    <Stack
      sx={{
        minHeight: "100vh",
        transition: "all .2s",
        opacity: 1,
        minWidth: "100vw",
        backgroundPosition: "center",
      }}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Stack sx={{ position: "fixed", top: 32, left: 32 }}>
        <ImgQuantifyFx />
      </Stack>
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ maxWidth: "1280px", width: "100%" }}
        direction={"row"}
      >
        {/* The signup form */}
        <Stack
          spacing="40px"
          sx={{
            p: "20px",
            borderRadius: "20px",
            width: "440px",
          }}
        >
          {/* Heading and Text  */}
          <Stack alignItems={"center"} spacing={"16px"}>
            <Stack spacing={"6px"} alignItems={"center"}>
              {isChanged && (
                <img
                  src={IMG_PASSWORD_CHANGED}
                  style={{
                    height: "75px",
                    width: "75px",
                    objectFit: "contain",
                  }}
                  alt=""
                />
              )}
              <Typography
                variant="h1"
                sx={{ fontSize: "24px", fontWeight: "600", lineHeight: "44px" }}
              >
                {isChanged ? "Password Changed" : "Set new password"}
              </Typography>
              <Typography variant="subtitle1" textAlign={"center"}>
                {isChanged ? "You can now log in with your new password. " : ""}
              </Typography>
            </Stack>
            {isChanged && (
              <Button
                fullWidth
                color="secondary"
                variant="outlined"
                disabled={busy}
                onClick={() => navigate("/login")}
              >
                Log in
              </Button>
            )}
          </Stack>

          {/* Form  */}
          {!isChanged && (
            <Stack spacing="20px">
              <AnimatePresence>
                {loginError && (
                  <motion.div
                    key={"loginError"}
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                  >
                    <Alert
                      variant="filled"
                      color="error"
                      icon={<IcAlertTriangle />}
                    >
                      <AlertTitle>Invalid verification code.</AlertTitle>
                      <Typography>
                        The code may have expired or is invalid.
                      </Typography>
                    </Alert>
                  </motion.div>
                )}
              </AnimatePresence>
              <ZInput
                label="Password"
                text={password}
                onUpdate={(t, s) => {
                  setPassword(t);
                  setStrength(s || 0);
                  setError("");
                }}
                password
                placeholder="Enter a strong password"
                showStrength
                errorText={
                  error === "password"
                    ? "Password must be at least 8 characters and contain at least one number, one uppercase letter, and a special character."
                    : ""
                }
              />
            </Stack>
          )}

          {/* Buttons  */}
          <Stack spacing={"16px"}>
            {!isChanged && (
              <>
                <Button
                  color="secondary"
                  variant="outlined"
                  disabled={busy}
                  onClick={submitNewPassword}
                >
                  Reset Password
                </Button>
                <Button
                  color="secondary"
                  variant="text"
                  disabled={busy}
                  startIcon={<IcArrowLeft />}
                  onClick={() => navigate("/login")}
                >
                  Back to log in
                </Button>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
