import React from "react";

function IcEye() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g stroke="#6B7280" strokeLinecap="round" strokeLinejoin="round">
        <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7z"></path>
        <path d="M12 15a3 3 0 100-6 3 3 0 000 6z"></path>
      </g>
    </svg>
  );
}

export default IcEye;
