import {
  Avatar,
  AvatarGroup,
  Button,
  Dialog,
  dialogClasses,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { IconButtonOutlined } from "../components/IconButtonOutlined";
import IcClose from "../assets/icons/IcClose";
import { TradingPlan } from "../types/TradingPlan";
import IcWhitePaper from "../assets/icons/white-paper";
import IcChecklist from "../assets/icons/check-list";
import IcNotificationCounter from "../assets/icons/IcNotificationCounter";
import IcCalendar from "../assets/icons/IcCalendar";
import { SYMBOLS } from "../assets/static";
import { Add } from "@mui/icons-material";
import TradingPlanEditor from "../components/TradingPlanEditor";
import {
  RtmGetLinkPreview,
  RtmGetTrade,
  RtmUpdateTrade,
} from "../core/rtm/user";
import IcAttachment from "../assets/icons/attachment";
import IcInformation from "../assets/icons/IcInformation";
import AddTradeActivity from "../components/AddTradeActivity";
import { useRecoilState } from "recoil";
import { aUser } from "../core/state";
import useRtmUpdate from "../core/rtm/useRtmUpdate";
import IcMaximize from "../assets/icons/IcMaximize";
import IcCurrency, { ExtractPairs } from "../assets/icons/IcCurrency";
import { TSTrade } from "../types/TSTrade";

function DialogSidebarButton(props: {
  selected?: boolean;
  icon?: any;
  onClick?: () => void;
}) {
  return (
    <IconButton
      onClick={props.onClick}
      sx={{
        p: "12px",
        borderRadius: "8px",
        background: props.selected ? "#2563EB1F" : "",
      }}
    >
      <SvgIcon
        htmlColor={props.selected ? "#2563EB" : "#6B7280"}
        sx={{ height: "24px", width: "24px", p: 0 }}
      >
        {props.icon}
      </SvgIcon>
    </IconButton>
  );
}

function PaperInput(props: {
  value?: string;
  onChange?: (text: string) => void;
}) {
  return (
    <Paper
      elevation={2}
      sx={{
        px: "12px",
        py: "10px",
        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={"10px"}>
        <IcAttachment />
        <input
          value={props.value}
          onChange={(e) => props.onChange?.(e.currentTarget.value)}
          placeholder="Insert TradingView Link"
          style={{
            outline: "none",
            border: "none",
            fontSize: "14px",
            fontWeight: "500",
            width: "100%",
          }}
        />
      </Stack>
    </Paper>
  );
}

export function LinkPreview(props: {
  preview: {
    title: string;
    description: string;
    thumbnail: string;
    url: string;
  };
}) {
  return (
    <Paper elevation={2} sx={{ overflow: "hidden" }}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack sx={{ p: "12px" }}>
          {/* Title  */}
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 14,
              color: "#020617",
            }}
          >
            {props.preview.title}
          </Typography>
          {/* Link */}
          <Typography
            sx={{
              color: "#2563EB",
              fontSize: 14,
              fontWeight: 400,
              cursor: "pointer",
            }}
            onClick={() => window.open(props.preview.url, "_blank")}
          >
            {props.preview.url}
          </Typography>
        </Stack>
        <img
          style={{ height: "70px", width: "100px", objectFit: "cover" }}
          src={props.preview.thumbnail}
          alt={props.preview.title}
        />
      </Stack>
    </Paper>
  );
}

export default function TradeDetailsDialog(props: {
  onClose?: () => void;
  trade: TSTrade;
}) {
  const theme = useTheme();
  const pairs = ExtractPairs(props.trade.symbol.split(".")[0]);

  const [profile] = useRecoilState(aUser);
  const [section, setSection] = useState<
    "details" | "calendar" | "activity" | "plan"
  >("details");
  const [fullwidth, setFullwidth] = useState(false);
  const [plan, setPlan] = useState<TradingPlan>();
  const [trade, setTrade] = useState<TSTrade>(props.trade);
  const [busy, setBusy] = useState(false);
  const [tradingViewLinkPreview, setTradingViewLinkPreview] = useState<{
    title: string;
    description: string;
    thumbnail: string;
    url: string;
  }>();

  const [tradeExitUpdate] = useRtmUpdate(
    "UPDATE_TRADE_EXIT",
    true,
    (_pos: TSTrade) => {
      // Ensure the update has the trade we are currently looking at.
      if (_pos.id === trade.id) {
        return true;
      }

      return false;
    }
  );

  const [positionsUpdate] = useRtmUpdate(
    "UPDATE_POSITIONS",
    !trade.close_time,
    (update) => {
      // Ensure the position we are looking at is in the update.
      for (let pos of update) {
        if (pos.id === trade.id) {
          // Process update
          return true;
        }
      }
      return false;
    }
  );

  async function load() {
    try {
      const _trade = await RtmGetTrade(trade.id);
      if (_trade.plan) {
        setPlan(_trade.plan);
      }
      setTrade(_trade);
      if (_trade.tradingViewLink) {
        const _preview = await RtmGetLinkPreview(_trade.tradingViewLink);
        if (_preview) {
          setTradingViewLinkPreview({
            ..._preview,
            url: _trade.tradingViewLink,
          });
        }
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading historical events for this event. ", {
        variant: "error",
      });
      console.error("Error loading historical events. ", err);
    }
  }

  function KeyValuePair(props: { label: string; value: any }) {
    return (
      <Stack
        sx={{
          p: "6px",
          borderRadius: "8px",
        }}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          sx={{
            color: "#6B7280",
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          {props.label}
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            color: "#020617",
          }}
        >
          {props.value}
        </Typography>
      </Stack>
    );
  }

  async function addActivity(content: string) {
    // Update trade with added activity
    await RtmUpdateTrade(trade.id, {
      activity: { type: "comment", text: content, time: moment().unix() },
    });
    await load();
  }

  async function closeAsync() {
    setBusy(true);

    await RtmUpdateTrade(trade.id, { plan: plan });

    props.onClose?.();
  }

  function RenderActivity(act: { type: string; text: string; time: number }) {
    if (act.type === "comment") {
      // Render comment
      return (
        <Paper elevation={2}>
          <Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ px: "16px", py: "12px" }}
            >
              {/* Avatar */}
              <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
                <Avatar
                  sx={{ height: "24px", width: "24px" }}
                  src={profile?.image}
                  alt={profile?.firstName}
                >
                  {profile?.firstName[0]}
                </Avatar>
                <Typography
                  sx={{ color: "#020617", fontSize: 14, fontWeight: 600 }}
                >
                  {profile?.firstName} {profile?.lastName}
                </Typography>
              </Stack>
              {/* Time */}
              <Typography
                sx={{ color: "#6B72807A", fontSize: 12, fontWeight: 400 }}
              >
                {moment.unix(act.time).format("DD.MM.yyyy | hh:mm")}
              </Typography>
            </Stack>
            <Typography
              sx={{
                color: "#020617",
                fontSize: 14,
                fontWeight: 400,
                px: "16px",
                py: 0,
              }}
              dangerouslySetInnerHTML={{ __html: act.text }}
            />
          </Stack>
        </Paper>
      );
    } else {
      // Render status
      return (
        <Stack
          direction={"row"}
          sx={{ p: "8px" }}
          spacing={"8px"}
          alignItems="center"
        >
          <IcInformation />
          <Typography
            sx={{
              color: "#6B7280",
              fontSize: 14,
              fontWeight: 500,
              flex: 1,
            }}
            dangerouslySetInnerHTML={{ __html: act.text }}
          />
          <Typography
            sx={{ color: "#6B72807A", fontSize: 12, fontWeight: 400 }}
          >
            {moment.unix(act.time).format("DD.MM.yyyy | hh:mm")}
          </Typography>
        </Stack>
      );
    }
  }

  useEffect(() => {
    if (positionsUpdate && positionsUpdate.length > 0) {
      const _eventPos = positionsUpdate[0] as TSTrade;
      setTrade({
        ...trade,
        ..._eventPos,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionsUpdate]);

  useEffect(() => {
    if (tradeExitUpdate) {
      // Reload
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeExitUpdate]);

  useEffect(() => {
    load();
  }, []);

  return (
    <Dialog
      open
      fullScreen
      onClose={closeAsync}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        padding: "8px",
        [`& .${dialogClasses.paper}`]: {
          borderRadius: "24px",
          background: "#FFF",
          minWidth: fullwidth ? "1000px" : "522px",
        },
      }}
    >
      <DialogTitle sx={{ p: "20px" }}>
        {/* Main stack as Row: With Title and Dialog Buttons (i.e. close button) */}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          spacing={"18px"}
        >
          {/* The event title */}
          <Stack spacing={"4px"}>
            {/* Row 1: Folder Icon and Event Title  */}
            <Stack direction={"row"} alignItems={"center"} spacing={"10px"}>
              <AvatarGroup
                spacing={"medium"}
                sx={{
                  "& div": {
                    height: "24px",
                    width: "24px",
                  },
                }}
              >
                {pairs.map((p) => (
                  <Avatar>
                    <IcCurrency name={p.toLowerCase()} />
                  </Avatar>
                ))}
              </AvatarGroup>

              <Typography
                sx={{
                  fontSize: 24,
                  fontWeight: 600,
                  color: theme.palette.text.primary,
                  textWrap: "wrap",
                }}
              >
                {trade.symbol}
              </Typography>
              <Stack
                sx={{
                  border: "1px solid #E5E7EB",
                  borderRadius: "6px",
                  px: "8px",
                  py: "4px",
                  background: trade.type.includes("sell")
                    ? "#FEE2E2"
                    : "rgba(41, 140, 107, 0.15)",
                }}
              >
                <Typography
                  sx={{
                    color: trade.type.includes("sell") ? "#991B1B" : "#10B981",
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  {trade.lots}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          {/* Dialog Actions: Close, Maximize, etc.  */}
          <Stack direction={"row"} alignItems={"center"} spacing={"6px"}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 500,
                color: "#6B7280",
              }}
            >
              #{trade.id}
            </Typography>
            <Divider
              orientation="horizontal"
              sx={{ height: "20px", width: "1px", borderColor: "#E5E7EB" }}
            />
            <IconButtonOutlined onClick={() => setFullwidth(!fullwidth)}>
              <IcMaximize />
            </IconButtonOutlined>
            <IconButtonOutlined onClick={closeAsync}>
              <IcClose />
            </IconButtonOutlined>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 0 }}>
        <Stack direction={"row"} sx={{ height: "100%", overflow: "hidden" }}>
          <Stack flex={1} sx={{ overflow: "auto" }}>
            {/* Content of Dialog  */}
            {section === "details" && (
              <Paper
                elevation={1}
                sx={{
                  flex: 1,
                  m: fullwidth ? "20px" : 0,
                  p: fullwidth ? "8px" : "20px",
                  border: fullwidth ? "1px solid #E5E7EB" : "none",
                }}
              >
                <Stack spacing={"8px"}>
                  {/* Description of the Symbol */}
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#6B7280",
                    }}
                  >
                    {SYMBOLS[trade.symbol.split(".")[0]]}
                  </Typography>
                  <Stack direction={"row"} sx={{ mt: "16px" }}>
                    <Paper
                      elevation={2}
                      sx={{ flex: 1, p: "16px", maxWidth: "200px" }}
                    >
                      <Stack spacing={"4px"}>
                        <Typography
                          sx={{
                            color: "#6B7280",
                            fontSize: 12,
                            fontWeight: 400,
                          }}
                        >
                          Total P&L
                        </Typography>
                        <Typography
                          sx={{
                            color: trade.profit > 0 ? "#298C6B" : "#EF4444",
                            fontSize: 18,
                            fontWeight: 400,
                          }}
                        >
                          {trade.profit > 0 ? "+" : "-"}${" "}
                          {Math.abs(trade.profit)}
                        </Typography>
                      </Stack>
                    </Paper>
                  </Stack>
                  <Paper
                    elevation={2}
                    sx={{
                      mt: "8px",
                      p: "12px",
                      background: "#FFF",
                      "& .MuiStack-root:nth-child(even)": {
                        background: "#F8FAFC",
                      },
                    }}
                  >
                    <KeyValuePair
                      label={"Entry price"}
                      value={trade.open_price}
                    />
                    {trade.close_time && (
                      <KeyValuePair
                        label={"Exit price"}
                        value={trade.close_price}
                      />
                    )}
                    <KeyValuePair
                      label={"Time entry"}
                      value={moment(trade?.open_time).format(
                        "HH:mm:ss | DD.MM.yyyy"
                      )}
                    />
                    {trade.close_time && (
                      <KeyValuePair
                        label={"Time exit"}
                        value={moment(trade.close_time).format(
                          "HH:mm:ss | DD.MM.yyyy"
                        )}
                      />
                    )}
                    {Boolean(trade.stop_loss) && (
                      <KeyValuePair
                        label={"Stop loss"}
                        value={trade.stop_loss}
                      />
                    )}
                    {Boolean(trade.take_profit) && (
                      <KeyValuePair
                        label={"Take profit"}
                        value={trade.take_profit}
                      />
                    )}
                    {trade.comment && (
                      <KeyValuePair label={"Comment"} value={trade.comment} />
                    )}
                    {trade.swap > 0 && (
                      <KeyValuePair label={"Swap"} value={trade.swap} />
                    )}
                    {trade.commission > 0 && (
                      <KeyValuePair
                        label={"Commission"}
                        value={trade.commission}
                      />
                    )}
                    {trade.state && (
                      <KeyValuePair label={"State"} value={trade.state} />
                    )}
                  </Paper>

                  {tradingViewLinkPreview && (
                    <LinkPreview preview={tradingViewLinkPreview} />
                  )}

                  <PaperInput
                    value={trade.tradingViewLink}
                    onChange={(t) =>
                      setTrade({
                        ...trade,
                        tradingViewLink: t,
                      })
                    }
                  />
                </Stack>
              </Paper>
            )}

            {(section === "plan" || (section === "details" && fullwidth)) && (
              <Paper elevation={1} sx={{ flex: 1, border: "none", p: "20px" }}>
                <Stack spacing={"8px"} sx={{}}>
                  {/* Description of the Symbol */}
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#6B7280",
                    }}
                  >
                    Trading Plan
                  </Typography>

                  {!plan && (
                    <Button
                      onClick={() => {
                        setPlan({ items: [] });
                      }}
                      size="small"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      startIcon={<Add />}
                    >
                      Add trading plan
                    </Button>
                  )}

                  {plan && (
                    <TradingPlanEditor
                      plan={plan}
                      onChange={(p) => setPlan(p)}
                    />
                  )}
                </Stack>
              </Paper>
            )}

            {section === "activity" && (
              <Paper
                elevation={1}
                sx={{
                  flex: 1,
                  border: "none",
                  p: "20px",
                  display: "flex",
                  overflow: "hidden",
                }}
              >
                <Stack spacing={"16px"} flex={1} sx={{ overflow: "hidden" }}>
                  {/* Description of the Symbol */}
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#6B7280",
                    }}
                  >
                    Notes
                  </Typography>

                  <Stack flex={1} spacing={"6px"} sx={{ overflow: "auto" }}>
                    {trade.activity?.map((act) => RenderActivity(act))}
                  </Stack>

                  {/* New Activity Component */}
                  <AddTradeActivity onSave={addActivity} />
                </Stack>
              </Paper>
            )}
          </Stack>
          {/* Sidebar */}
          {!fullwidth && (
            <Stack
              spacing={"8px"}
              sx={{
                borderLeft: "1px solid #E5E7EB",
                background: "#fff",
                height: "100%",
                py: "24px",
                px: "8px",
              }}
            >
              <DialogSidebarButton
                icon={<IcWhitePaper />}
                onClick={() => setSection("details")}
                selected={section === "details"}
              />
              <DialogSidebarButton
                icon={<IcChecklist />}
                onClick={() => setSection("plan")}
                selected={section === "plan"}
              />
              <DialogSidebarButton
                icon={<IcNotificationCounter />}
                onClick={() => setSection("activity")}
                selected={section === "activity"}
              />
              <DialogSidebarButton
                icon={<IcCalendar />}
                onClick={() => setSection("calendar")}
                selected={section === "calendar"}
              />
            </Stack>
          )}
          {fullwidth && (
            <Stack sx={{ overflow: "hidden" }}>
              <Paper
                elevation={1}
                sx={{
                  flex: 1,
                  border: "none",
                  p: "20px",
                  display: "flex",
                  overflow: "hidden",
                }}
              >
                <Stack spacing={"16px"} flex={1} sx={{ overflow: "hidden" }}>
                  {/* Description of the Symbol */}
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#6B7280",
                    }}
                  >
                    Notes
                  </Typography>

                  <Stack flex={1} spacing={"6px"} sx={{ overflow: "auto" }}>
                    {trade.activity?.map((act) => RenderActivity(act))}
                  </Stack>

                  {/* New Activity Component */}
                  <AddTradeActivity onSave={addActivity} />
                </Stack>
              </Paper>
            </Stack>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
