import React from "react";

function IcEyeOff() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g stroke="#6B7280" strokeLinecap="round" strokeLinejoin="round">
        <path d="M9.88 9.88a3 3 0 104.24 4.24"></path>
        <path d="M10.73 5.08c.421-.052.845-.08 1.27-.08 7 0 10 7 10 7a13.163 13.163 0 01-1.67 2.68"></path>
        <path d="M6.61 6.61A13.526 13.526 0 002 12s3 7 10 7a9.74 9.74 0 005.39-1.61"></path>
        <path d="M2 2l20 20"></path>
      </g>
    </svg>
  );
}

export default IcEyeOff;
