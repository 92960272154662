import React from "react";

function IcChevronUpDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke="#6B7280"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.083 8.75L7 11.667 9.917 8.75M4.083 5.25L7 2.333 9.917 5.25"
      ></path>
    </svg>
  );
}

export default IcChevronUpDown;
