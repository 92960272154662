import * as React from "react";

const IcQuantify: React.FC<React.SVGProps<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="#231F20"
      d="M10 2.5c-4.142 0-7.5 3.385-7.5 7.562 0 3.709 2.649 6.794 6.144 7.438v-2.432a5.184 5.184 0 0 1-3.79-5.006c0-2.866 2.304-5.189 5.146-5.189s5.146 2.323 5.146 5.189c0 .923-.24 1.79-.659 2.542l1.708 1.722a7.57 7.57 0 0 0 1.305-4.263C17.5 5.886 14.143 2.5 10 2.5"
    ></path>
    <path
      fill="#231F20"
      d="M10 17.5V10l7.5 7.46h-3.32l-1.82-1.815V17.5z"
    ></path>
  </svg>
);

export default IcQuantify;
