import React from "react";
import { useLocation, useNavigate } from "react-router";
import LoadingOverlay from "../components/LoadingOverlay";

export default function AuthHandler() {
  const loc = useLocation();
  const nav = useNavigate();
  const params = new URLSearchParams(loc.search);
  const mode = params.get("mode");
  const code = params.get("oobCode");
  const stamp = params.get("stamp");
  const email = params.get("email");
  React.useEffect(() => {
    // Check the code and action
    switch (mode) {
      case "resetPassword":
        // Display reset password handler and UI.
        nav("/update-password", { state: { code: code } });
        break;
      case "verifyEmail":
        // Display email verification handler and UI.
        nav("/verify-email", {
          state: { code: code, email: email, stamp: stamp },
        });
        break;
      default:
        // Error: invalid mode.
        nav("/");
    }
  }, []);
  return <LoadingOverlay />;
}
