import { Stack } from "@mui/material";

export default function LoadingOverlay() {
  return (
    <Stack
      sx={{
        display: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "#FFF",
      }}
    ></Stack>
  );
}
