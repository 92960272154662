import axios from "axios";
import { AppConfig } from "../../config";
import { GetCurrentToken } from "../firebase";

const BASE_URL = AppConfig.api + "/accounts";

/**
 * Called after a successful signup event. Sends an account confirmation email for the logged in user.
 */
export async function RestPostSignup(firstName: string, lastName: string) {
  const token = await GetCurrentToken();

  // Send the request
  await axios.post(
    BASE_URL + "/signup",
    { firstName, lastName },
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

/**
 * Called after a successful verification event. Sends a welcome email for the registered user.
 */
export async function RestPostVerification(email: string) {
  // Send the request
  await axios.post(BASE_URL + "/confirmed", {
    email: email,
  });
}

/**
 * Sends a password reset link via email. Called from forgot password section.
 */
export async function RestRequestPasswordReset(email: string) {
  // Send the request
  await axios.post(BASE_URL + "/recover", {
    email: email,
  });
}
