import React from "react";

function IcGift() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_1147_1267)">
        <path
          d="M13.3333 8V14.6667H2.66667V8"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.6667 4.66666H1.33333V7.99999H14.6667V4.66666Z"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 14.6667V4.66666"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 4.66668H5C4.55797 4.66668 4.13405 4.49108 3.82149 4.17852C3.50893 3.86596 3.33333 3.44204 3.33333 3.00001C3.33333 2.55798 3.50893 2.13406 3.82149 1.8215C4.13405 1.50894 4.55797 1.33334 5 1.33334C7.33333 1.33334 8 4.66668 8 4.66668Z"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 4.66668H11C11.442 4.66668 11.866 4.49108 12.1785 4.17852C12.4911 3.86596 12.6667 3.44204 12.6667 3.00001C12.6667 2.55798 12.4911 2.13406 12.1785 1.8215C11.866 1.50894 11.442 1.33334 11 1.33334C8.66667 1.33334 8 4.66668 8 4.66668Z"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1147_1267">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IcGift;
