import { Stack, Typography } from "@mui/material";
import { Route, Routes } from "react-router";
import SidebarUser from "../../components/UserSidebar";
import SectionTradingJournal from "./user/trading-journal";
import PageUserDashboard from "./user";
import { aUser } from "../../core/state";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { RtmGetProfile } from "../../core/rtm";
import { enqueueSnackbar } from "notistack";
import useRtmUpdate from "../../core/rtm/useRtmUpdate";
import AccountOnboarding from "../../components/AccountOnboarding";
import { TSTrade } from "../../types/TSTrade";
export default function PageDashboard() {
  const [, setProfile] = useRecoilState(aUser);
  const [busy, setBusy] = useState(false);

  const [tradeExitUpdate] = useRtmUpdate("UPDATE_TRADE_EXIT", true, () => true);
  const [tradeEnterUpdate] = useRtmUpdate(
    "UPDATE_TRADE_ENTERED",
    true,
    () => true
  );

  async function load() {
    try {
      setBusy(true);
      const _acc = await RtmGetProfile();
      setProfile(_acc);
    } catch (err: any) {
      enqueueSnackbar("Error getting profile data. ", { variant: "error" });
      console.error("Error getting profile. ", err);
    }
    setBusy(false);
  }

  useEffect(() => {
    if (tradeExitUpdate) {
      const _pos = tradeExitUpdate as TSTrade;
      enqueueSnackbar(
        <Stack>
          <Typography>
            Exited {_pos.symbol} at {_pos.close_price} with {_pos.profit}{" "}
            profit.
          </Typography>
        </Stack>,
        {
          variant: _pos.profit > 0 ? "success" : "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
          autoHideDuration: 10000,
          hideIconVariant: true,
        }
      );
    }
  }, [tradeExitUpdate]);

  useEffect(() => {
    if (tradeEnterUpdate) {
      const _pos = tradeEnterUpdate as TSTrade;
      enqueueSnackbar(
        <Stack>
          <Typography>
            Entered {_pos.symbol} at {_pos.open_price}.
          </Typography>
        </Stack>,
        {
          variant: _pos.type.includes("buy") ? "success" : "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
          autoHideDuration: 10000,
          hideIconVariant: true,
        }
      );
    }
  }, [tradeEnterUpdate]);

  useEffect(() => {
    load();
  }, []);
  return (
    <Stack
      sx={{
        height: "100vh",
        transition: "all .2s",
        opacity: 1,
        minWidth: "100vw",
        overflow: "hidden",
      }}
      alignItems={"start"}
      justifyContent={"stretch"}
    >
      <Stack
        sx={{
          maxWidth: "1440px",
          width: "100%",
          flex: 1,
          overflow: "hidden",
          position: "relative",
        }}
        direction={"row"}
      >
        {/* Sidebar */}
        <SidebarUser />

        <Stack flex={1} overflow={"hidden"}>
          <Stack sx={{ overflow: "hidden" }} flex={1}>
            <Routes>
              <Route
                element={<SectionTradingJournal />}
                path="/trading-journal"
              />
              <Route element={<AccountOnboarding />} path="/connect-account" />
              <Route path="/" element={<PageUserDashboard />} />
            </Routes>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
