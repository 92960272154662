// The file contains the Real-Time Messaging API functions.
import { RtmClient } from "@zexcore/rtm-client";
import { FbAuth } from "../firebase";
import { AppConfig } from "../../config";
import { PaginatedResponse } from "../../types/PaginatedResponse";
import { NewsEvent } from "../../types/NewsEvents";
import { UserAccount } from "../../types/UserAccount";

export type RtmClientEvent =
  | "UPDATE_POSITIONS"
  | "UPDATE_TRADE_ENTERED"
  | "UPDATE_TRADE_EXIT"
  | "UPDATE_SYMBOL";
let Client: RtmClient;
export let ConnectionState: "CONNECTED" | "CONNECTING" | "DISCONNECTED" =
  "DISCONNECTED";

let ConnectionStateListener:
  | null
  | ((state: "CONNECTED" | "CONNECTING" | "DISCONNECTED") => void);

function SetConnectionState(
  newState: "CONNECTED" | "CONNECTING" | "DISCONNECTED"
) {
  ConnectionState = newState;
  if (ConnectionStateListener) {
    ConnectionStateListener(newState);
  }
}

export function setConnectionStateListener(
  action: (state: "CONNECTED" | "CONNECTING" | "DISCONNECTED") => void
) {
  ConnectionStateListener = action;
  return () => {
    ConnectionStateListener = null;
  };
}

export async function EnsureRtmConnection() {
  return new Promise(async (resolve, reject) => {
    if (ConnectionState === "CONNECTED" && Client) {
      resolve(Client);
      return;
    } else if (ConnectionState === "CONNECTING") {
      while (ConnectionState === "CONNECTING") {
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
      if (ConnectionState === "CONNECTED") {
        resolve(Client);
      } else {
        reject("Connection error");
      }
      return;
    }
    if (!Client) {
      SetConnectionState("CONNECTING");
      const token = await FbAuth.currentUser?.getIdToken();
      Client = new RtmClient(AppConfig.rtm, {
        reconnectDelayMs: 5000,
        onReconnecting(attempt) {
          console.log(`Trying to reconnect for ${attempt} time...`);
        },
        authenticationData: "firebase:" + token,
        async onOpen() {
          SetConnectionState("CONNECTED");
          resolve(true);
        },
        onClose() {
          SetConnectionState("DISCONNECTED");
        },

        onError() {
          console.log("Error connecting to the RTM server");
          reject();
        },
      });
    } else {
      resolve(true);
    }
  });
}

export async function GetRtmClient() {
  await EnsureRtmConnection();
  return Client;
}

export function RtmSubscribe(
  update: RtmClientEvent,
  callback: (data: any) => void
) {
  if (!Client || ConnectionState !== "CONNECTED") {
    console.error("Called subscribe before client initialization. ");
  }
  return Client.Subscribe(update, (data) => {
    console.log(`[${update}]:`, data);
    callback(data);
  });
}

// All the common functions are defined here.

export async function RtmGetProfile() {
  const _rtm = await GetRtmClient();
  const prof = await _rtm.CallWait<UserAccount>("RtmGetProfile");
  return prof;
}

export async function RtmGetEvents(props: {
  // page: number;
  filter?: {
    date?: "today" | "week" | "month" | number;
    now: string;
    impact?: string[];
  };
  sort?: {
    type: "title" | "created" | "date";
    value: "desc" | "asc";
  };
  // search?: string;
}) {
  const _rtm = await GetRtmClient();
  const _events = await _rtm.CallWait<NewsEvent[]>("RtmGetEvents", {
    // page: props.page,
    filter: props.filter,
    sort: props.sort,
    // search: props.search,
  });
  return _events;
}

export async function RtmGetEventHistory(id: string) {
  const _rtm = await GetRtmClient();
  const _events = await _rtm.CallWait<NewsEvent[]>("RtmGetEventHistory", {
    event: id,
  });
  return _events;
}

export async function RtmUpdatePreferences(preferences: any) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>(
    "RtmUpdatePreferences",
    preferences
  );
  return _result;
}
