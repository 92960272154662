import * as React from "react";

const IcClose: React.FC<React.SVGProps<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="currentColor"
      d="m10.884 10 5.366-5.366-.884-.884L10 9.116 4.634 3.75l-.884.884L9.116 10 3.75 15.366l.884.884L10 10.884l5.366 5.366.884-.884z"
    ></path>
  </svg>
);

export default IcClose;
