import React from "react";

function IcCheckCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g stroke="#115E59" strokeLinecap="round" strokeLinejoin="round">
        <path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 100 16.666z"></path>
        <path d="M7.5 10l1.667 1.667L12.5 8.333"></path>
      </g>
    </svg>
  );
}

export default IcCheckCircle;
