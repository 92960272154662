// material-ui
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

// ==============================|| CALENDAR - STYLED ||============================== //

const ExperimentalStyled = styled(Box)(({ theme }) => ({
  // hide license message
  "& .fc-license-message": {
    display: "none",
  },
  "& .fc .fc-daygrid .fc-scroller-liquid-absolute": {
    overflow: "auto",
    border: "0px",
  },

  "& .fc-col-header ": {
    width: "100% !important",
  },

  "& .fc .fc-daygrid-body ": {
    width: "100% !important",
  },

  "& .fc-scrollgrid-sync-table": {
    width: "100% !important",
  },

  // basic style
  "& .fc": {
    // "--fc-bg-event-opacity": 1,
    "--fc-border-color": theme.palette.divider,
    // "--fc-daygrid-event-dot-width": "10px",
    "--fc-today-bg-color": theme.palette.primary.light,
    // "--fc-list-event-dot-width": "10px",
    // "--fc-event-border-color": theme.palette.primary.dark,
    "--fc-now-indicator-color": theme.palette.error.main,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    border: "1px solid #E5E7EB",
    background: "#F8FAFC",
    overflow: "hidden",
    borderRadius: "12px",
  },

  // date text
  "& .fc .fc-daygrid-day-top": {
    display: "grid",
    "& .fc-daygrid-day-number": {
      margin: 8,
      color: "#5F6B83",
      fontSize: 14,
      fontWeight: 500,
      marginBottom: 12,
      textAlign: "end",
    },
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 10,
  },

  "& .fc .fc-col-header-cell-cushion": {
    color: "#5F6B83",
    padding: 16,
    fontSize: 14,
    fontWeight: 500,
  },

  // events
  "& .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start":
    {
      marginLeft: 4,
      marginBottom: 6,
      borderRadius: 4,
      background: "transparent",
      border: "none",
    },

  // // popover when multiple events
  "& .fc .fc-more-popover": {
    border: "0.5px solid #E5E7EB",
    borderRadius: 12,
    zIndex: 1200,
    overflow: "auto",
    boxShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
    background: "#FFF",
    maxHeight: "500px",
  },
  "& .fc-more-popover::-webkit-scrollbar": {
    display: "none",
  },

  // "& .fc .fc-more-popover .fc-popover-body": {
  //   background: theme.palette.secondary.light,
  //   borderBottomLeftRadius: 4,
  //   borderBottomRightRadius: 4,
  // },

  "& .fc .fc-popover-header": {
    display: "none",
  },

  // agenda view
  "& .fc-theme-standard .fc-list-day-cushion": {
    background: theme.palette.secondary.light,
  },

  "& .fc .fc-day": {
    cursor: "pointer",
    background: "#FFF",
    position: "relative",
  },

  "& .fc .fc-timeGridDay-view .fc-timegrid-slot": {
    background: theme.palette.background.paper,
  },

  "& .fc .fc-timegrid-slot": {
    cursor: "pointer",
    color: "#5F6B83",
    fontSize: 14,
    fontWeight: 600,
    background: "#FFF",
  },

  "& .fc-timegrid-slot.fc-timegrid-slot-lane.fc-timegrid-slot-minor, .fc-timegrid-slot.fc-timegrid-slot-label.fc-timegrid-slot-minor":
    {
      border: "0px",
    },

  "& .fc-timegrid-slot-lane": {
    borderLeft: "1px solid #E5E7EB !important",
  },

  // weekday
  "& .fc .fc-col-header-cell": {
    background: "#F8FAFC",
    border: "1px solid #E5E7EB",
    borderRight: "1px solid #E5E7EB",
    borderLeft: "1px solid #E5E7EB",
  },

  // "& .fc .fc-list-event:hover td": {
  //   cursor: "pointer",
  //   background: theme.palette.secondary.light,
  // },

  "& .fc-timegrid-event": {
    background: "transparent",
    border: "none",
    zIndex: 0,
  },

  // "& .event-hovered": {
  //   zIndex: 2000,
  //   height: "200px",
  //   width: "200px",
  // },
  "& .weekly-more-link": {},

  "& .fc-daygrid-day": {
    height: "100px",
  },

  "& .fc-daygrid-day-frame": {
    display: "flex",
    flexDirection: "column",
  },
  "& .fc-daygrid-day-events": {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  "& .fc-daygrid-event-harness": {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  "& .fc-event.fc-event-start": {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    margin: "4px !important",
  },
  "& .fc-event-main": {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  "& .fc-timegrid-event-harness": {
    minHeight: "70px",
  },
}));

export default ExperimentalStyled;
