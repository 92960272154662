import { Button, Link, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import { enqueueSnackbar } from "notistack";
import { FbAuth } from "../../core/firebase";
import ZInput from "../../components/ZInput";
import { RestPostSignup } from "../../core/rest/accounts";
import ImgQuantifyFx from "../../assets/images/quantifyfx";
import { motion } from "framer-motion";
import IcArrowLeft from "../../assets/icons/arrow-left";
import { LOGIN_SLIDE } from "../../assets/images";

export default function PageSignup() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const [created, setCreated] = useState(false);
  const [passwordHasEnoughCharacters, setPasswordHasEnoughCharacters] =
    useState(false);
  const [passwordHasSymbol, setPasswordHasSymbol] = useState(false);

  const navigate = useNavigate();

  async function signup(fe: any) {
    try {
      fe?.preventDefault?.();
      if (!firstName) {
        setError("firstName");
        return;
      }
      if (!lastName) {
        setError("lastName");
        return;
      }
      if (!email) {
        setError("email");
        return;
      }
      if (!password || !passwordHasEnoughCharacters || !passwordHasSymbol) {
        setError("password");
        return;
      }
      setBusy(true);

      // Create account on firebase.
      const acc = await createUserWithEmailAndPassword(FbAuth, email, password);
      if (acc.user) {
        // Signed up. We set the user's name.
        await updateProfile(acc.user, {
          displayName: firstName + " " + lastName,
        });
        // We create the local user on backend, which also sends confirmation email.
        await RestPostSignup(firstName, lastName);
        setCreated(true);
        setBusy(false);
        return true;
      }
    } catch (err: any) {
      if (err.code) {
        if (err.code === "auth/weak-password") {
          enqueueSnackbar("Password must be atleast 6 characters long.", {
            variant: "error",
          });
          return false;
        } else if (err.code === "auth/email-already-in-use") {
          // Try signing in. If we succeed, we then re-send email verification. Otherwise, we login the user.
          try {
            const creds = await signInWithEmailAndPassword(
              FbAuth,
              email,
              password
            );
            if (creds && creds.user.emailVerified) {
              return true;
            } else if (creds && !creds.user.emailVerified) {
              // Send verification email
              setBusy(false);
              return true;
            }
          } catch (err: any) {
            // Invalid password?
            signOut(FbAuth);
            if (err.code && err.code === "auth/internal-error") {
              enqueueSnackbar("Too many attempts, please try again later. ", {
                variant: "error",
              });
            } else {
              enqueueSnackbar(
                "Error signing up. Please try again in a few minutes.",
                { variant: "error" }
              );
            }
          }
        } else if (err.code === "auth/internal-error") {
          enqueueSnackbar("Too many tries. Please try again later. ", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(
            "Error signing up. Please try again or contact administrators.",
            { variant: "error" }
          );
        }
      } else {
        enqueueSnackbar(
          "Unknown error signing up. Please try again or contact administrators.",
          { variant: "error" }
        );
      }
    }
    setBusy(false);
    return false;
  }

  async function load() {
    if (FbAuth.currentUser) {
    } else {
      console.log("Not logged in.");
    }
  }

  useEffect(() => {
    return FbAuth.onAuthStateChanged((state) => {
      load();
    });
  }, []);

  useEffect(() => {
    const symPat = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/@#]/;
    setPasswordHasSymbol(symPat.test(password));
    setPasswordHasEnoughCharacters(password.length >= 8);
  }, [password]);

  return (
    <Stack
      sx={{
        minHeight: "100vh",
        transition: "all .2s",
        opacity: 1,
        minWidth: "100vw",
        overflow: "clip",
        background: "#FFF",
        position: "relative",
      }}
      alignItems={"center"}
    >
      <Stack sx={{ position: "fixed", top: 32, left: 32 }}>
        <ImgQuantifyFx />
      </Stack>
      <Stack
        flex={1}
        sx={{ maxWidth: "1280px", width: "100%", alignSelf: "start" }}
        direction={"row"}
      >
        {/* The signup form */}
        <Stack
          justifyContent={"center"}
          alignItems={"start"}
          spacing="40px"
          sx={{
            height: "100vh",
            px: "96px",
            position: "relative",
          }}
        >
          {!created && (
            <>
              <Typography
                variant="h1"
                sx={{ fontSize: "24px", fontWeight: "600" }}
              >
                Sign up
              </Typography>
              <Stack spacing="20px" sx={{ width: "400px" }}>
                <Stack direction={"row"} spacing={"8px"}>
                  <ZInput
                    label="First Name"
                    placeholder="John"
                    text={firstName}
                    onUpdate={(t) => setFirstName(t)}
                    errorText={
                      error === "firstName" ? "Enter your first name." : ""
                    }
                  />
                  <ZInput
                    label="Last Name"
                    placeholder="Doe"
                    text={lastName}
                    onUpdate={(t) => setLastName(t)}
                    errorText={
                      error === "lastName" ? "Enter your last name." : ""
                    }
                  />
                </Stack>
                <ZInput
                  label="Email address"
                  placeholder="johndoe@example.com"
                  text={email}
                  onUpdate={(t) => setEmail(t)}
                  errorText={
                    error === "email" ? "Enter your email address." : ""
                  }
                />
                <ZInput
                  label="Password"
                  text={password}
                  onUpdate={(t) => setPassword(t)}
                  password
                  placeholder="Enter a strong password"
                  showStrength
                  errorText={
                    error === "password"
                      ? "Password must be at least 8 characters and contain at least one number, one uppercase letter, and a special character."
                      : ""
                  }
                  onReturn={signup}
                />
              </Stack>

              <Stack sx={{ minWidth: "400px" }} spacing={"8px"}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={busy}
                  onClick={signup}
                >
                  Sign up
                </Button>
                <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                  Already have an account? <Link href="/login">Log in</Link>
                </Typography>
              </Stack>
            </>
          )}
          {created && (
            <>
              <Stack alignItems={"start"} spacing={"6px"}>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: "24px",
                    fontWeight: "600",
                    lineHeight: "44px",
                  }}
                >
                  Email Verification
                </Typography>
                <Stack spacing="24px" alignItems={"start"}>
                  <Typography variant="subtitle1" textAlign={"center"}>
                    We have sent a verification email to
                    <b>{" " + email}</b>.
                  </Typography>
                  <Typography fontSize={14} color={"#6B7280"}>
                    Didn't receive the email?{" "}
                    <Link sx={{ cursor: "pointer" }} onClick={() => {}}>
                      Click to resend it
                    </Link>
                  </Typography>
                  <Button
                    color="secondary"
                    variant="text"
                    disabled={busy}
                    startIcon={<IcArrowLeft />}
                    onClick={() => navigate("/login")}
                  >
                    Back to log in
                  </Button>
                </Stack>
              </Stack>
            </>
          )}
          <Stack
            alignItems={"center"}
            sx={{ position: "absolute", bottom: 32, left: 32, right: 32 }}
          >
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 600,
                alignSelf: "center",
                textAlign: "center",
              }}
            >
              By signing up you accept our{" "}
              <Link href="/tnc">Terms and Conditions</Link>
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {/* Layer 1 */}
      <motion.img
        src={LOGIN_SLIDE}
        style={{
          objectFit: "contain",
          height: "100%",
          top: "40%",
          left: "40%",
          position: "absolute",
          filter: "drop-shadow(0px 12px 32px rgba(0, 0, 0, 0.12)) blur(1px)",
        }}
        initial={{
          opacity: 0,
          transform: "translate(50%, 0)",
        }}
        animate={{
          zIndex: 1,
          transform: "translate(0, 0)",
          opacity: 0.32,
        }}
        transition={{ duration: 1, delay: 1.5 }}
      />

      {/* Layer 2 */}
      <motion.img
        src={LOGIN_SLIDE}
        style={{
          objectFit: "contain",
          height: "100%",
          top: "40%",
          left: "40%",
          position: "absolute",
          filter: "drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.12)) blur(0.4px)",
        }}
        initial={{
          opacity: 0,
          transform: "translate(50%, -60px)",
        }}
        animate={{
          zIndex: 1,
          transform: "translate(0, -60px)",
          opacity: 0.4,
        }}
        transition={{ duration: 1, delay: 1.8 }}
      />

      {/* Layer 3 */}
      <motion.img
        src={LOGIN_SLIDE}
        style={{
          objectFit: "contain",
          height: "100%",
          top: "40%",
          left: "40%",
          position: "absolute",
          filter: "drop-shadow(0px 12px 12px rgba(0, 0, 0, 0.12)) blur(0.2px)",
        }}
        initial={{
          opacity: 0,
          transform: "translate(50%, -120px)",
        }}
        animate={{
          zIndex: 1,
          transform: "translate(0, -120px)",
          opacity: 0.6,
        }}
        transition={{ duration: 1, delay: 2 }}
      />

      {/* Layer 4 */}
      <motion.img
        src={LOGIN_SLIDE}
        style={{
          objectFit: "contain",
          height: "100%",
          top: "40%",
          left: "40%",
          position: "absolute",
          filter: "drop-shadow(0px 12px 6px rgba(0, 0, 0, 0.12)) ",
        }}
        initial={{
          opacity: 0,
          transform: "translate(50%, -180px)",
        }}
        animate={{
          zIndex: 1,
          transform: "translate(0, -180px)",
          opacity: 1,
        }}
        transition={{ duration: 1, delay: 2.1 }}
      />
    </Stack>
  );
}
